.qr-codes-filter-screen .screen-content {
  position: relative;

  .filters-content {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .filter-sections {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 30%;

    .filter-section {
      display: block;
    }

    .select-filter-section-btn {
      display: block;
      font-size: rem-calc(15);
      height: rem-calc(36);
      line-height: 1.2;
      padding: rem-calc(0 16);
      text-align: left;
      width: 100%;
      color: $black;

      &.active {
        background-color: $white;

        .num-selected {
          display: block;
        }
      }
    }

    .num-selected {
      display: none;
      color: $secondary-color;
      font-size: rem-calc(10);
    }
  }

  .filters-menu {
    background-color: $white;
    width: 70%;
    overflow-y: auto;

    .filters-menu-options-list {
      background-color: $white;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .filters-menu-option {
      padding: rem-calc(4 0);
      cursor: pointer;
      min-height: rem-calc(36);
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:not(:last-child) {
        border-bottom: 1px solid $athens-gray;
      }

      .filters-full-path {
        font-size: rem-calc(15);
        margin-left: rem-calc(4);
        padding: 0 rem-calc(16);
        color: $dusty-gray;
      }

      .filters-menu-option-btn {
        align-items: center;
        display: flex;
        font-size: rem-calc(17);
        // min-height: rem-calc(36);
        line-height: rem-calc(22);
        padding: rem-calc(0 16);
        text-align: left;
        width: 100%;
        color: $black;

        &::before {
          border: 1px solid $dusty-gray;
          border-radius: 50%;
          content: "";
          display: block;
          flex-shrink: 0;
          height: rem-calc(14);
          margin-right: rem-calc(10);
          width: rem-calc(14);
        }

        &.active::before {
          background-color: $dusty-gray;
        }
      }
    }
  }

  .capacity-filter-input-section {
    border-top: 0;
    align-items: center;
    display: flex;
    color: $dusty-gray;
    font-size: rem-calc(17);
    line-height: rem-calc(22);
    letter-spacing: rem-calc(-0.41);
    text-decoration: none;
    padding: rem-calc(2 0);
    margin: 0;

    .capacity-filter-btn {
      appearance: none;
      color: $dusty-gray;
      font-size: rem-calc(17);
      line-height: rem-calc(22);
      letter-spacing: rem-calc(-0.41);
      flex-shrink: 0;
      text-decoration: none;
      margin: 0;
      padding: 0;
      text-align: center;
      width: rem-calc(40);
    }

    .capacity-filter-input-wrapper {
      flex: 1;

      [type="text"] {
        text-align: center;
        width: 100%;
      }
    }
  }
}
