.endesa-count-wrapper {
  .single-gauge-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: rem-calc(10);
    padding: rem-calc(10 0);
    .gauge-label {
      font-size: rem-calc(17);
      width: 100%;
      text-align: center;
    }
    .gauge-values {
      font-size: rem-calc(15);
      width: 100%;
      text-align: center;
      color: $bright-gray;
      margin-bottom: rem-calc(20);
    }
  }
}
