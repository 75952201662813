.powered-by-clebex {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: rem-calc(156);

  .text {
    color: $bright-gray;
    font-size: rem-calc(10);
    line-height: rem-calc(13);
    letter-spacing: rem-calc(-0.56);
    text-transform: uppercase;
  }

  img {
    width: rem-calc(95);
  }
}