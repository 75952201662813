.receptions-screen {
  input[type="text"],
  input[type="email"],
  textarea {
    &:focus-visible {
      outline: none;
    }
  }
  &.reception-email-screen {
    flex: 1;

    .screen-content {
      display: flex;
      flex-direction: column;
    }

    .clebex-item-section-item,
    .clebex-item-section {
      &.mail-flex {
        border: none;
        display: flex;
        flex: 1;
        margin-top: 0;

        .clebex-item-content-wrapper {
          align-items: normal;
        }
      }
    }

    .clebex-section-input {
      &.preview {
        background-color: $athens-gray;
      }

      input[type="text"],
      textarea {
        &:focus-visible {
          outline: none;
        }
      }

      textarea {
        resize: none;
        display: flex;
        flex: 1;
      }

      .clebex-section-input-label {
        &.reception-mail-variables {
          color: $french-gray;

          &.variable {
            font-style: italic;
            color: $french-gray;
            font-weight: bolder;
          }
        }
      }
    }
  }
}
