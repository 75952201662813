.availability-list {
  color: $dusty-gray;
  font-size: rem-calc(14);
  list-style: none;
  margin: 0;
  padding: 0;

  .availability-item {
    cursor: pointer;
  }

  .date-range {
    padding-bottom: rem-calc(8);
  }

  .hours-and-weekdays {
    display: flex;
    justify-content: space-between;
    padding-bottom: rem-calc(8);
  }

  .weekdays {
    display: flex;
    > span {
      display: block;
      padding: rem-calc(0 2);
    }
  }

  .checkbox label {
    color: $dusty-gray;
    font-size: rem-calc(14);
  }

  .negative-margin {
    margin: rem-calc(0 0 0 -16);
  }
}
.clebex-item-range-wrapper {
  flex-grow: 1;
}
.clebex-item-content-range {
  &.hours-and-weekdays {
    justify-content: center;
    .weekdays {
      margin-left: rem-calc(10);
      display: flex;
      > span {
        display: block;
        padding: rem-calc(0 2);
      }
    }
  }
}

.unavailability-list {
  color: $dusty-gray;
  font-size: rem-calc(14);
  list-style: none;
  margin: 0;
  padding: 0;

  .unavailability-item {
    cursor: pointer;
  }

  .checkbox label {
    color: $dusty-gray;
    font-size: rem-calc(14);
  }

  .date-range {
    padding-top: rem-calc(8);
    > span {
      display: block;
      padding-bottom: rem-calc(8);
    }
  }

  .negative-margin {
    margin: rem-calc(0 -16);
  }
}
.resources-availability {
}
