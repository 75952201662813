.bookings-screen {
  &.full-width {
    flex: 1;
    container-type: inline-size;
  }
  .bookings-body {
    position: relative;
    height: 100%;
    border: 1px solid $dusty-gray;
    border-radius: rem-calc(10);
    margin: rem-calc(30 10 40 10);
    padding: rem-calc(15 5);
    @container (#{map-get($grid-breakpoints, two)}) {
      padding: rem-calc(5);
    }
    .booking-preset-name {
      font-weight: $font-weight-medium;
      position: absolute;
      top: rem-calc(-9);
      left: 50%;
      transform: translate(-50%, 0);
      background-color: $athens-gray;
      padding: rem-calc(0 16);
      font-size: rem-calc(17);
    }
    .booking-preset-icon-wrapper {
      position: absolute;
      display: flex;
      width: rem-calc(50);
      right: rem-calc(10);
      &.bottom {
        bottom: rem-calc(-13);
        svg {
          transform: rotate(-90deg);
        }
      }
      &:not(.bottom) {
        top: rem-calc(-14);
      }
      &.search {
        width: rem-calc(100);
      }
      @container (#{map-get($grid-breakpoints, one)}) {
        right: rem-calc(15);
      }
      @container (#{map-get($grid-breakpoints, two)}) {
        right: rem-calc(20);
      }
      @container (#{map-get($grid-breakpoints, three)}) {
        right: rem-calc(30);
      }
      @container (#{map-get($grid-breakpoints, four)}) {
        right: rem-calc(40);
      }
      @container (#{map-get($grid-breakpoints, five)}) {
        right: rem-calc(50);
      }
      .booking-preset-icon {
        width: rem-calc(40);
        height: rem-calc(24);
        cursor: pointer;
        font-weight: $font-weight-medium;
        background-color: $primary-color;
        border-radius: rem-calc(8);
        padding: rem-calc(4 12);
        margin-right: rem-calc(10);
        .svg-icon {
          height: rem-calc(16);
          --color-1: white;
        }
      }
    }
    .clebex-item-section-item {
      padding: 0 !important;
      &:last-child.row {
        padding-bottom: rem-calc(8) !important;
      }
      .clebex-item-content-wrapper {
        @container (#{map-get($grid-breakpoints, two)}) {
          align-items: center !important;
          flex-direction: row !important;
          padding: 0 !important;
          .label {
            padding: rem-calc(4);
          }
        }
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        padding: rem-calc(10 20 10 60);
        color: inherit;
        text-decoration: none;

        &.search {
          height: rem-calc(40);
          padding: rem-calc(0 10);
          margin: rem-calc(10 0);
          .search-container {
            border-radius: rem-calc(10);
            overflow: hidden;
            width: 100%;
            input {
              width: 90%;
            }
            .clear-field {
              width: 10%;
              position: initial;
              svg {
                width: rem-calc(20) !important;
                height: rem-calc(20) !important;
              }
            }
          }
        }
        .checkbox.slide {
          margin-right: rem-calc(16);
        }

        .booking-item-column {
          padding: rem-calc(0 10);
          flex: 1;
          display: flex;
          align-items: center;
          @container (#{map-get($grid-breakpoints, two)}) {
            align-items: center;
          }
          .column-name {
            font-weight: 500;
            margin-right: rem-calc(10);
            @container (#{map-get($grid-breakpoints, two)}) {
              display: none;
            }
          }
          &:last-child {
            justify-content: space-between;
          }
          svg {
            &.success {
              --color-1: #19aa00;
            }
            &.error {
              --color-1: #ff3131;
            }
            &.warning {
              --color-1: #f0be00;
            }
          }
          .checkbox.tiny.alt label > .svg-icon {
            left: rem-calc(3);
          }
          .declaration-arrow {
            position: absolute;
            right: rem-calc(10);
            top: 50%;
            transform: translate(0, -50%);
            @container (#{map-get($grid-breakpoints, two)}) {
              position: inherit;
              transform: none;
            }
          }
          .declaration-checkbox-wrapper {
            display: flex;
            align-items: center;
            position: absolute;
            left: rem-calc(10);
            top: 50%;
            transform: translate(0, -50%);
            @container (#{map-get($grid-breakpoints, two)}) {
              position: inherit;
              transform: none;
              margin-right: rem-calc(10);
            }
          }
          .checkbox {
            width: auto;
            label {
              background-color: white;
              &::before {
                left: 0;
              }
              padding: rem-calc(0 20 0 0);
            }
          }
        }
        &.grey-bg {
          background: $whiteIce;
          font-weight: 500;

          div {
            &:first-child {
              width: rem-calc(55);
            }

            &:last-child {
              width: rem-calc(40);
            }
          }
        }
        .clebex-section-input {
          .approval-history {
            display: flex;
            padding: rem-calc(5 16);
            align-items: center;
            justify-content: space-between;
            color: $dusty-gray;
            font-size: rem-calc(15);
          }
        }
      }
    }
    .bookings-header {
      margin: rem-calc(10 0);
      display: none;

      @container (#{map-get($grid-breakpoints, two)}) {
        display: block;
      }

      .clebex-item-content-wrapper {
        .label {
          cursor: pointer;
          height: rem-calc(40);
          padding: 0;
          text-align: center;
          position: relative;
          &:not(:last-child)::after {
            position: absolute;
            content: "";
            right: 0;
            top: rem-calc(10);
            border-right: 1px solid $dusty-gray;
            height: rem-calc(20);
          }
          .highlight {
            height: rem-calc(40);
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        @container (#{map-get($grid-breakpoints, two)}) {
          flex-direction: row;
          align-items: center;
          padding: 0;
        }
      }
    }
  }
  .clebex-section-input {
    .approval-history {
      display: flex;
      padding: rem-calc(5 16);
      align-items: center;
      justify-content: space-between;
      color: $dusty-gray;
      font-size: rem-calc(15);
    }
  }
  .bookings-modal-slide-wrapper {
    text-align: left;
    .clebex-item-section {
      background-color: transparent;
      box-shadow: none;
      margin: rem-calc(8 0);
      &.declaration-details {
        margin: 0;
      }
    }
  }
  .checkbox.slide {
    margin-right: rem-calc(16);
  }
}
