.plan-resources {
  .clebex-item-section {
    &.title {
      margin-bottom: 0;
    }
    &.section-scroll {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0;
      max-height: rem-calc(132);
      overflow-y: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      scroll-snap-type: mandatory;
      scroll-snap-points-y: repeat(164px);
      scroll-snap-type: y mandatory;
      &::-webkit-scrollbar {
        display: none;
      }
      .clebex-item-section-item {
        width: 50%;
        .clebex-item-content-wrapper {
          border-radius: rem-calc(16);
          box-shadow: rem-calc(0 1.5 3) rgba($black, 0.16);
          .label {
            font-size: rem-calc(14);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-height: rem-calc(15);
            max-height: rem-calc(34);
          }
        }
        &:nth-child(even) {
          padding-left: 5px;
        }
        &:nth-child(odd) {
          padding-right: 5px;
        }
      }
    }
  }
}
.edit-level {
  .img-wrapper {
    margin: rem-calc(27 16 4 16);
    img {
      border-radius: rem-calc(10);
    }
  }
}
