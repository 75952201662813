.list-of-events-by-date {
  list-style: none;
  margin: 0;
  padding: rem-calc(0 0 10);

  .event-date-label {
    color: $dusty-gray;
    font-size: rem-calc(15);
    font-weight: $font-weight-medium;
    letter-spacing: -0.83px;
    line-height: 20 / 15 * 1em;
    margin: 0;
    padding: rem-calc(11 20 0);

    &.today {
      color: $primary-color;
    }
  }

  .list-of-events-for-current-date {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .list-event {
    display: flex;
    padding: rem-calc(6 16);
  }

  .list-event-wrapper {
    backdrop-filter: blur(20px);
    background-color: $white;
    box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
    border-radius: rem-calc(14);
    color: $bright-gray;
    padding: rem-calc(6 16);
    text-align: left;
    width: 100%;

    &.active {
      background-color: $primary-color;

      .event-title,
      .event-time,
      .event-description {
        color: $white;
      }
    }
  }

  .event-title {
    display: block;
    font-size: rem-calc(17);
    line-height: 22 / 17 * 1em;
    letter-spacing: -0.41px;
    &.subject {
      font-weight: $font-weight-medium;
    }
  }

  .event-time {
    color: rgba($bright-gray, 0.8);
    display: flex;
    font-size: rem-calc(15);
    line-height: 20 / 15 * 1em;
    letter-spacing: -0.24px;
  }

  .event-description {
    color: $dusty-gray;
    font-size: rem-calc(13);
    line-height: 16 / 13 * 1em;
  }
}

.context-menu {
  bottom: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;

  .backdrop {
    background-color: rgba($black, 0.5);
    bottom: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  .options-list {
    box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
    margin: rem-calc(12 8);
    position: relative;
    z-index: 2;
    width: 70%;
  }

  .context-item {
    background-color: $white;
    border-bottom: 1px solid #ccc;
    overflow: hidden;

    &:first-child {
      border-radius: rem-calc(8 8 0 0);
    }

    &:last-child {
      border-radius: rem-calc(0 0 8 8);
      border-bottom: 0;
    }
  }

  .context-button {
    align-items: center;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    color: $bright-gray;
    display: flex;
    font-size: rem-calc(17);
    height: rem-calc(45);
    justify-content: space-between;
    letter-spacing: -0.41px;
    line-height: 22 / 17 * 1em;
    padding: rem-calc(0 16);
    text-align: left;
    width: 100%;

    .svg-icon {
      height: rem-calc(20);
      width: rem-calc(20);
    }

    svg {
      height: rem-calc(20);
    }
  }
}
