.edit-connector {
  .profile-data-list {
    .info {
      border: none;
    }
  }

  .form-group {
    &.input {
      margin-top: 1.17rem;
      background-color: #ffffff;
      border-top: 1px solid rgba(60, 60, 67, 0.29);
      border-bottom: 1px solid rgba(60, 60, 67, 0.29);

      input {
        height: 36px;
        border: none;
        padding: 0 0 0 1rem;
        color: #737373;
        -webkit-text-fill-color: #737373;
      }

      textarea {
        color: #737373;
        -webkit-text-fill-color: #737373;
      }

      svg {
        fill: grey;
      }

      .tiny-label {
        color: $bright-gray;
        font-size: 17px;
        text-transform: none;
      }

      .field-wrapper-with-button {
        display: flex;
        justify-content: space-between;

        button {
          position: relative;
          border: none;
          padding-left: 0.8rem;
          padding-right: 0.8rem;
          display: block;
          width: auto;
        }
      }
    }
  }
}
