.reception-desk-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background: #57c2b7 0% 0% no-repeat padding-box;
    gap: 2.5%;
    padding: 2.5%;
  }
  
  .template-error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    .resource {
      font: normal normal 500 3vh Roboto;
      display: flex;
      margin-bottom: 2vh;
    }
  
    .reception-desk-error-message {
      font: normal normal 350 3vh Roboto;
      display: flex;
      margin-bottom: 10vh;
      color: white;
    }
  
    img {
      width: 10vw;
      margin-bottom: 4vw;
    }
  }
  
  .rd-template-1 {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3vh;

    .reception-desk {
      height: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5%;
      padding-right: 5%;
      box-sizing: border-box;
      width: 100%;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 4vh;
      border: 0.5px solid #707070;
      box-shadow: 0px 13px 16px #00000029;
      overflow: hidden;
    }

    .reception-desk-location {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .reception-desk-location svg {
        height: 1.1em;
        width: 1.1em;
        fill: #737373 !important;
        position: relative;
        top: 12%;
    }

    .svg-icon {
      display: inline;
    }
  
    .name {
      text-align: left;
      font: normal normal 400 4em Roboto;
      letter-spacing: -1.44px;
      color: #373F4E;
      opacity: 1;
      line-height: normal;
      text-align: center;
    }
  
    .title {
      font: normal normal 350 2.5em Roboto;
      letter-spacing: -1.68px;
      color: #737373;
      opacity: 1;
      line-height: normal;
      text-align: center;
    }
  
    .options {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 1.5vw;
    }

    .other-options {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: row;
      gap: 1.5vw;
      align-items: start;
      flex-wrap: wrap;
      flex-grow: 1;
    }
  
    .map, .cancel, .video, .translations, .videoconference {
      background: white;
      box-shadow: 0px 13px 16px #00000029;
      border: 0.5px solid #707070;
      border-radius: 4vh;
      opacity: 1;
      aspect-ratio : 1 / 1;
      height: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #737373;
      cursor: pointer;
      width: 200px;
      height: 200px;
    }

    .camera {
      background: white;
      border-radius: 4vh;
      opacity: 1;
      aspect-ratio : 1 / 1;
      height: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #737373;
      cursor: pointer;
      margin-top: 3vh;
    }

    .reception-desk-logo {
      aspect-ratio : 1 / 1;
      height: 250px;
      display: flex;
      flex-direction: column;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 13px 16px #00000029;
      border: 0.5px solid #707070;
      border-radius: 4vh;
      opacity: 1;
      overflow: hidden;
    }
  
    .company-logo {
      width: 100%;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .company-logo img {
      height: 80%;
    }
  
    .clebex-logo {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .clebex-logo img {
      height: 80%;
    }
  
    .powered-by-clebex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      height: 20%;
      width: 100%;
      margin-bottom: 1vh;
    }
  
    .powered-by-clebex span {
      margin-right: 10px;
      font-size: 1vh;
    }
  
    .powered-by-clebex img {
      width: 6vh;
    }

    .camera-div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      width: 100%;
      background: #000000 0% 0% no-repeat padding-box;
      border-radius: 4vh;
      border: 0.5px solid #707070;
      box-shadow: 0px 13px 16px #00000029;
      overflow: hidden;
    }

    .visit-div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 4vh;
      border: 0.5px solid #707070;
      box-shadow: 0px 13px 16px #00000029;
      overflow: hidden;
      padding: 3%;
      gap: 2vh;
    }

    .visit-div-messages {
      justify-content: center;
      position: relative;
    }

    .visit-success {
      fill: #19AA00;
    }

    .visit-info {
      fill: #5a6aa6bf;
    }

    .visit-warning {
      fill: #f1a604;
    }

    .visit-error {
      fill: #FF3232;
    }

    .visit-message {
      font: normal normal 300 2.5em Roboto;
      width: 100%;
      text-align: center;
    }

    .message-welcome {
      font: normal normal 300 2.5em Roboto;
      width: 100%;
      text-align: center;
    }

    .visit-host, .visit-time, .visit-subject, .visit-resource {
      font-size: 2em;
      width: 100%;
      text-align: center;
    }

    hr {
      border-top: 2px solid #c8c5c5;
      width: 50%;
    }
  
  }

  .rd-template-2 {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 3vh;

    .template-2-left {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 3vh;
      justify-content: space-between;
      flex: 1;
    }

    .template-2-right {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 18%;
    }

    .reception-desk {
      height: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5%;
      padding-right: 5%;
      box-sizing: border-box;
      width: 100%;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 4vh;
      border: 0.5px solid #707070;
      box-shadow: 0px 13px 16px #00000029;
      overflow: hidden;
    }

    .reception-desk-location {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .reception-desk-location svg {
      height: 1.1em;
      width: 1.1em;
      fill: #737373 !important;
      position: relative;
      top: 12%;
  }

  .svg-icon {
    display: inline;
  }
  
    .name {
      text-align: left;
      font: normal normal 400 4em Roboto;
      letter-spacing: -1.44px;
      color: #373F4E;
      opacity: 1;
      line-height: normal;
      text-align: center;
    }
  
    .title {
      font: normal normal 350 2.5em Roboto;
      letter-spacing: -1.68px;
      color: #737373;
      opacity: 1;
      line-height: normal;
      text-align: center;
    }
  
    .options {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      box-sizing: border-box;
      align-items: end;
      gap: 1.5vw;
    }
  
    .map, .cancel, .video, .translations, .videoconference {
      background: white;
      box-shadow: 0px 13px 16px #00000029;
      border: 0.5px solid #707070;
      border-radius: 4vh;
      opacity: 1;
      aspect-ratio : 1 / 1;
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #737373;
      cursor: pointer;
      min-width: 70%;
    }

    .camera {
      background: white;
      border-radius: 4vh;
      opacity: 1;
      aspect-ratio : 1 / 1;
      height: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #737373;
      cursor: pointer;
      margin-top: 3vh;
    }

    .reception-desk-logo {
      display: flex;
      flex-direction: column;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 13px 16px #00000029;
      border: 0.5px solid #707070;
      border-radius: 4vh;
      opacity: 1;
      overflow: hidden;
    }
  
    .company-logo {
      width: 100%;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .company-logo img {
      height: 80%;
    }
  
    .clebex-logo {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .clebex-logo img {
      height: 80%;
    }
  
    .powered-by-clebex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      height: 20%;
      width: 100%;
      margin-bottom: 1vh;
    }
  
    .powered-by-clebex span {
      margin-right: 10px;
      font-size: 1vh;
    }
  
    .powered-by-clebex img {
      width: 6vh;
    }

    .camera-div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      width: 100%;
      background: #000000 0% 0% no-repeat padding-box;
      border-radius: 4vh;
      border: 0.5px solid #707070;
      box-shadow: 0px 13px 16px #00000029;
      overflow: hidden;
    }

    .visit-div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 4vh;
      border: 0.5px solid #707070;
      box-shadow: 0px 13px 16px #00000029;
      overflow: hidden;
      padding: 3%;
      gap: 2vh;
    }

    .visit-div-messages {
      justify-content: center;
    }

    .visit-success {
      fill: #19AA00;
    }

    .visit-info {
      fill: #5a6aa6bf;
    }

    .visit-warning {
      fill: #f1a604;
    }

    .visit-error {
      fill: #FF3232;
    }

    .visit-message {
      font: normal normal 300 2.5em Roboto;
      width: 100%;
      text-align: center;
    }

    .message-welcome {
      font: normal normal 300 2.5em Roboto;
      width: 100%;
      text-align: center;
    }

    .visit-host, .visit-time, .visit-subject, .visit-resource {
      font-size: 2em;
      width: 100%;
      text-align: center;
    }

    hr {
      border-top: 2px solid #c8c5c5;
      width: 50%;
    }
  
  }
  
  .template-map {
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    visibility: hidden;

    .back-to-main-page {
      background-color: white;
      cursor: pointer;
      position: absolute;
      top: 2vh;
      left: 2vw;
      border-radius: 50%;
      font: normal normal bold 2.5vh Roboto;
      z-index: 1000;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      opacity: 1;
      fill: #999999;
      width: 6.5vh;
      height: 6.5vh;
    }
    
    .map-date-time {
        width: 25vh;
        height: 6.5vh !important;
        background-color: #eeeef0;
        border: 1px solid #707070;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 2vh;
        right: 2vw;
        height: 6vh;
        border-radius: 2vh;
        overflow: hidden;
        font: normal normal 400 2.7vh Roboto !important;
          border-radius: 3vh;
        padding-top: 0.5vh;
    }
    
    .map-date {
      color: #737373;
      font-size: 1.8vh;
      font-weight: 450;
      margin-bottom: 0.2vh;
    }
    
    .map-time {
      font-size: 1.8vh;
      font-weight: 450;
      margin-bottom: 0.2vh;
    }
  
    .back-to-main-page-div {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 100000 !important;
    }

  }
  
  .template-videoconference {
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;

    .back-to-main-page {
      background-color: white;
      cursor: pointer;
      position: absolute;
      top: 2vh;
      left: 2vw;
      border-radius: 50%;
      font: normal normal bold 2.5vh Roboto;
      z-index: 1000;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      opacity: 1;
      fill: #999999;
      width: 6.5vh;
      height: 6.5vh;
    }
  
    .back-to-main-page-div {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 100000 !important;
    }

  }
  