.checkbox-day-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: rem-calc(0 4);
  justify-content: space-between;
  &.nested {
    margin: 0 -1rem;
  }

  .checkbox-wrapper {
    width: 1/7 * 100%;

    .checkbox.tiny.alt label {
      padding-left: rem-calc(22);
      color: $bright-gray;
      font-size: rem-calc(13);

      &::before {
        left: rem-calc(6);
      }

      > .svg-icon {
        left: rem-calc(9);
      }
    }
  }
}
