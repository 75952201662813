.screen {
  border-left: 1px solid $mischa;
  border-right: 1px solid $mischa;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  position: relative; // used to nest screen modal

  .screen-header {
    flex-shrink: 0;
    position: relative;
    background-color: white;

    .open-main-menu {
      display: block;

      @media (#{map-get($grid-breakpoints, one)}) {
        display: none;
      }
    }
    .toggle-main-menu {
      display: none;

      @media (#{map-get($grid-breakpoints, one)}) {
        display: block;
      }
    }
    .clebex-pagination-count-wrapper {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -30px;
      min-width: 100px;
      height: 30px;
      z-index: 1;
      text-align: center;
      padding: 5px 20px;
      border: 2px solid $athens-gray;
      border-radius: 7px;
      color: $dusty-gray;
      background-color: $white;
      font-size: 12px;
      font-weight: $font-weight-semi-bold;
      line-height: 16px;
    }
  }

  &.theme-company {
    .top-header-menu {
      background-color: rgba($primary-color, .8);
      color: $white;

      .svg-icon {
        fill: $white;
      }
    }

    .search-wrapper {
      background-color: rgba($primary-color, .5);
    }
  }

  &.theme-empire {
    .top-header-menu {
      background-color: rgba($empire-color, .8);
      color: $white;

      .svg-icon {
        fill: $white;
      }
    }

    .search-wrapper {
      background-color: rgba($empire-color, .5);
    }
  }

  &.theme-alternate {
    .top-header-menu {
      background-color: rgba($alternate-login, .8);;
      color: $white;

      .svg-icon {
        fill: $white;
      }
    }

    .search-wrapper {
      background-color: rgba($alternate-login, .5);
    }
  }

  .search-wrapper {
    padding: rem-calc(10 16 10 16);
  }

  &.gray-bg {
    background-color: $athens-gray;
  }

  .screen-content-wrapper {
    padding: rem-calc(0 16);
  }
}

.screen-footer {
  background-color: $footer-color-no-transparency;
  border-top: 1px solid rgba($tuna, .29);
  flex-shrink: 0;
  height: rem-calc(70);
  padding-bottom: rem-calc(21);

  @media #{$touch-device-query} {
    height: rem-calc(49);
    padding-top: 0;
  }

  .submit-action {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: rem-calc(16);
    width: 100%;

    .btn {
      text-align: center;
      line-height: rem-calc(28);
      width: 100%
    }
  }
}

.screen-content {
  flex: 1 1 auto;
  overflow: auto;
  height: 100%;
}
