.welcome {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  @media (#{map-get($grid-breakpoints, one)}) {
    cursor: default;
    pointer-events: none;
  }
  .large-logo-img {
    max-height: 60%;
    max-width: 60%;
    &.company-img {
      margin-bottom: rem-calc(40);
    }
    &.clebex-img {
      margin-bottom: rem-calc(20);
    }
    &.hidden {
      visibility: hidden;
    }
  }

  .large-logo {
    .powered-by-clebex {
      margin-bottom: rem-calc(20) !important;
    }

    .powered-by-clebex {
      margin: auto;
    }
  }

  h1 {
    font-size: rem-calc(28);
    font-weight: $font-weight-semi-bold;
  }
}
