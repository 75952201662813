.pass-screen {
  background-color: $athens-gray;
}

.pass-view {
  background-color: $white;
  border-radius: rem-calc(20);
  box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
  margin: rem-calc(26) auto 0;
  position: relative;
  text-align: center;
  width: rem-calc(320);

  &::before {
    background-color: rgba($white, 0.7);
    box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
    bottom: rem-calc(20);
    border-radius: rem-calc(20 0 0 20);
    content: "";
    display: block;
    position: absolute;
    right: 100%;
    top: rem-calc(20);
    width: rem-calc(10);
  }

  &::after {
    background-color: rgba($white, 0.7);
    box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
    bottom: rem-calc(20);
    border-radius: rem-calc(0 20 20 0);
    content: "";
    display: block;
    left: 100%;
    position: absolute;
    top: rem-calc(20);
    width: rem-calc(10);
  }

  .not-authorized {
    color: $error-color;
    display: flex;
    flex-direction: column;
    height: rem-calc(672);
    justify-content: center;

    .svg-icon,
    svg {
      fill: $error-color;
      height: rem-calc(200);
      margin: 0 auto;
      width: rem-calc(200);
    }

    .not-authorized-title {
      font-size: rem-calc(30);
      font-weight: $font-weight-bold;
      line-height: rem-calc(39);
      margin: rem-calc(11 0 0);
    }
  }
}

.pass-indicator {
  padding: rem-calc(20 0);

  .pass-indicator-button {
    box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
    align-items: center;
    background-color: $dusty-gray;
    border-radius: 50%;
    display: flex;
    height: rem-calc(176);
    margin: auto;
    justify-content: center;
    padding: 0;
    width: rem-calc(176);

    &.active {
      background-color: $success-color;
    }

    &.warning {
      background-color: $warning-color;
    }

    &.error {
      background-color: $error-color;
    }

    &.resource-assignment {
      background-color: $mulberry;
      color: white;
      --color-4: white;
    }

    .svg-icon,
    svg {
      fill: $white;
      --color-6: white;
      height: rem-calc(76);
      width: rem-calc(82);
    }
  }
}

.pass-desc {
  color: $dusty-gray;
  font-size: rem-calc(35);
  font-weight: $font-weight-semi-bold;
  line-height: rem-calc(34);
  letter-spacing: rem-calc(-0.84);

  .name {
    color: $secondary-color;
    text-transform: uppercase;
  }

  .place {
    display: block;
    margin: 0 auto;
    font-size: rem-calc(24);
    line-height: rem-calc(34);
    letter-spacing: -0.58px;
    width: rem-calc(250);
  }
}

.pass-time {
  padding: rem-calc(20 0 10);

  .date {
    color: $dusty-gray;
    font-size: rem-calc(24);
    line-height: rem-calc(34);
    letter-spacing: -0.58px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    &.hidden {
      visibility: hidden;
    }
  }

  .time {
    color: $primary-color;
    font-size: rem-calc(27);
    line-height: rem-calc(35);
    font-weight: $font-weight-bold;

    .check-in-time {
      align-items: center;
      color: $dusty-gray;
      display: flex;
      font-size: rem-calc(17px);
      font-weight: $font-weight-regular;
      letter-spacing: rem-calc(-0.41);
      line-height: rem-calc(18px);
      justify-content: center;
      width: 100%;
    }
  }
}

.pass-content {
  .powered-by-clebex {
    padding-bottom: rem-calc(8);
    margin: auto;
  }

  .qr-code {
    margin-bottom: rem-calc(9);
  }
}

.pass-list {
  list-style: none;
  margin: rem-calc(26) auto;
  padding: 0;
  width: rem-calc(320);

  .small-pass {
    background-color: $white;
    box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
    border: 1px solid $dusty-gray;
    border-radius: rem-calc(20);
    padding: rem-calc(22 13 20 22);
    position: relative;

    &:not(:last-child) {
      margin-bottom: -119px;
    }

    &.edit {
      padding-left: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .invisible-button {
    bottom: 0;
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .info-wrapper {
    display: flex;
    height: 69px;
    width: 100%;
    .svg-icon,
    svg {
      --color-6: white;
    }
  }

  .info {
    padding-top: 5px;
  }

  .checkbox {
    margin: auto 0;

    &.tiny label > .svg-icon {
      left: 3px;
    }
  }

  .delete-button-wrapper {
    align-items: center;
    display: flex;
  }

  .delete-button {
    padding: 10px;
    margin: 0;

    .svg-icon {
      height: 24px;
      width: 24px;
    }
  }

  .icon {
    background-color: $dusty-gray;
    border-radius: rem-calc(50%);
    height: rem-calc(54);
    width: rem-calc(54);

    &.active {
      background-color: $success-color;
    }

    &.warning {
      background-color: $warning-color;
    }

    &.error {
      background-color: $error-color;
    }

    &.resource-assignment {
      background-color: $mulberry;
      color: white;
      --color-4: white;
    }
  }

  .svg-icon {
    align-items: center;
    display: flex;
    height: rem-calc(54);
    justify-content: center;
    width: rem-calc(54);

    svg {
      --color-101: #{$white};
      height: rem-calc(25);
      width: rem-calc(24);
    }
  }

  .info {
    color: $dusty-gray;
    list-style: none;
    margin: 0;
    padding: rem-calc(0 0 0 18);
    text-align: left;
    font-size: rem-calc(15);
    font-weight: $font-weight-medium;
    line-height: 20/15 * 1em;
    letter-spacing: rem-calc(-0.36);
  }

  .name {
    color: $secondary-color;
  }

  .location span {
    display: block;
  }

  .time-wrapper {
    color: $dusty-gray;
    list-style: none;
    margin: 0 0 0 auto;
    padding: 0;
    text-align: right;
    font-size: rem-calc(14);
    line-height: 19/14 * 1em;
    &.hidden {
      visibility: hidden;
    }

    time {
      display: block;
    }

    .time {
      color: $primary-color;
    }
  }

  .qr-code-wrapper {
    display: flex;
    margin-top: rem-calc(10);
    margin-left: 9px;

    .qr-code {
      height: rem-calc(88);
      padding: 4px;
      width: rem-calc(88);
    }

    .svg-icon,
    svg {
      height: rem-calc(80);
      width: rem-calc(80);
    }
  }

  .powered-by-clebex {
    margin-left: auto;
  }
}
