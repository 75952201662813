.page {
  display: flex;
  height: 100%;
  //overflow: hidden;
  transition: transform 0.3s ease-in-out;
  width: 100%;

  &:not(.menu-hidden) {
    @media (#{map-get($grid-breakpoints, one)}) {
      padding-left: $menu-width;
    }
    .menu-wrapper {
      overflow: hidden;
    }
  }

  &.menu-hidden {
    .menu-wrapper {
      @media (#{map-get($grid-breakpoints, one)}) {
        display: none;
      }
    }
  }

  //&.menu-open .menu-wrapper {
  //  box-shadow: rem-calc(0 10 24) rgba($humming-bird, 0.5);
  //  transform: translateX(0);
  //
  //  @media (#{map-get($grid-breakpoints, one)}) {
  //    box-shadow: none;
  //  }
  //}

  &.menu-open {
    transform: translateX($mobile-menu-width);

    @media (#{map-get($grid-breakpoints, one)}) {
      transform: translateX(0);
    }

    .menu-close-overlay {
      display: block;

      @media (#{map-get($grid-breakpoints, one)}) {
        display: none;
      }
    }
  }

  .menu-close-overlay {
    background-color: rgba($black, 0.5);
    bottom: 0;
    box-shadow: none;
    display: none;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.menu-wrapper {
  background-color: $white;
  border-left: 1px solid #dadade;
  border-right: 1px solid #dadade;
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  top: 0;
  max-width: 100%;
  width: $mobile-menu-width;
  z-index: map-get($zindex, menu);

  @media (#{map-get($grid-breakpoints, one)}) {
    transition: none;
    transform: translateX(0);
    width: $menu-width;
  }
}

.main-content {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.screens-wrapper {
  display: flex;
  height: 100%;
  max-width: 100%;

  &.full-width-screen {
    width: 100% !important;
  }

  @media (#{map-get($grid-breakpoints, one)}) {
    width: $screen-width;
  }

  @media (#{map-get($grid-breakpoints, two)}) {
    width: $screen-width * 2;
  }

  @media (#{map-get($grid-breakpoints, three)}) {
    width: $screen-width * 3;
  }

  @media (#{map-get($grid-breakpoints, four)}) {
    width: $screen-width * 4;
  }

  @media (#{map-get($grid-breakpoints, three)}) {
    width: $screen-width * 3;
  }

  @media (#{map-get($grid-breakpoints, four)}) {
    width: $screen-width * 4;
  }

  @media (#{map-get($grid-breakpoints, five)}) {
    width: $screen-width * 5;
  }

  @media (#{map-get($grid-breakpoints, six)}) {
    width: $screen-width * 6;
  }

  @media (#{map-get($grid-breakpoints, seven)}) {
    width: $screen-width * 7;
  }

  .screen {
    display: none;
    max-width: 100%;
    width: 100%;

    @media (#{map-get($grid-breakpoints, one)}) {
      width: $screen-width;
    }

    // display all screens on this breakpoint
    // @media (#{map-get($grid-breakpoints, four)}) {
    //   display: flex;
    // }

    &.fluid {
      flex: 1;
    }

    &.show-footer-only-on-last-screen .screen-footer > * {
      display: none;
    }

    &:last-child {
      display: flex;

      &.show-footer-only-on-last-screen .screen-footer > * {
        display: block;
      }
    }

    // last two elements
    &:nth-last-child(-n + 2) {
      @media (#{map-get($grid-breakpoints, two)}) {
        display: flex;
      }
    }

    // last three elements
    &:nth-last-child(-n + 3) {
      @media (#{map-get($grid-breakpoints, three)}) {
        display: flex;
      }
    }

    // last four elements
    &:nth-last-child(-n + 4) {
      @media (#{map-get($grid-breakpoints, four)}) {
        display: flex;
      }
    }

    // last five elements
    &:nth-last-child(-n + 5) {
      @media (#{map-get($grid-breakpoints, five)}) {
        display: flex;
      }
    }

    // last six elements
    &:nth-last-child(-n + 6) {
      @media (#{map-get($grid-breakpoints, six)}) {
        display: flex;
      }
    }

    // last seven elements
    &:nth-last-child(-n + 7) {
      @media (#{map-get($grid-breakpoints, seven)}) {
        display: flex;
      }
    }
  }
}
