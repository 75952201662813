.clebex-pill-menu {
  background-color: $athens-gray;
  border-radius: rem-calc(9);
  display: flex;
  list-style: none;
  margin: 0;
  padding: rem-calc(2);
  position: relative;
  width: 100%;

  .pill-menu-indicator {
    height: rem-calc(32);
    left: 0;
    padding: rem-calc(2);
    position: absolute;
    transition: transform .3s ease-in-out;
    top: 0;
    z-index: 1;

    &::after {
      background-color: $white;
      border-radius: rem-calc(9);
      box-shadow: rem-calc(0 3 8) rgba($black, .12);
      height: rem-calc(28);
      content: '';
      display: block;
    }
  }

  .clebex-pill-menu-item {
    flex: 1 0 auto;
    position: relative;
    z-index: 2;
  }

  .clebex-pill-menu-item-cta {
    color: $bright-gray;
    font-size: rem-calc(14);
    height: rem-calc(28);
    line-height: rem-calc(21);
    letter-spacing: rem-calc(-0.22);
    text-align: center;
    width: 100%;
  }
}
