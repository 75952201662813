.login-layout {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: $bright-gray;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/images/cx-log1-bg-800x370.png");
  @media (#{map-get($grid-breakpoints, two)}) {
    background-color: $athens-gray;
    background-image: none;
  }

  .content {
    width: 90%;
    @media only screen and (max-width: 668px) and (max-height: 376px) {
      width: 50%;
    }
    @media only screen and (min-width: 700px) {
      width: 50%;
    }
    @media (#{map-get($grid-breakpoints, two)}) {
      width: 80%;
      height: 75%;
    }
    @media (#{map-get($grid-breakpoints, three)}) {
      width: 70%;
    }
    @media (#{map-get($grid-breakpoints, four)}) {
      width: 60%;
    }
    @media (#{map-get($grid-breakpoints, five)}) {
      width: 50%;
    }
    .login-block-wrapper {
      height: 100%;
      background-color: $athens-gray-alt;
      border-radius: 40px;
      border: 0.5px solid #707070;
      box-shadow: 0px 13px 16px #00000029;
      @media (#{map-get($grid-breakpoints, two)}) {
        display: flex;
        background-color: $white;
      }
      .large-logo {
        border-radius: 40px;
        box-shadow: 0px 3px 6px #00000029;
        padding: 50px;
        @media (#{map-get($grid-breakpoints, two)}) {
          width: 45%;
          box-shadow: none;
          position: relative;
        }
        @media only screen and (max-width: 668px) and (max-height: 376px) {
          display: none;
        }
        @media only screen and (max-width: 917px) and (max-height: 541px) {
          display: none;
        }
        @media only screen and (max-height: 650px) {
          display: none;
        }
        .large-logo-img {
          max-height: 100%;
          max-width: 100%;
          &.company-img {
            height: 150px;
            width: 150px;
            margin-bottom: 40px;
            @media (#{map-get($grid-breakpoints, two)}) {
              height: 400px;
              width: 400px;
            }
          }

          @media (#{map-get($grid-breakpoints, two)}) {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            margin-bottom: auto !important;
            padding: 60px;
          }
          &.hidden {
            visibility: hidden;
          }
        }
        .powered-by-clebex {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }

  .page-title-container {
    .page-title {
      letter-spacing: rem-calc(-2);
    }
  }
  .form-block-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (#{map-get($grid-breakpoints, two)}) {
      background-image: url("../assets/images/cx-log1-bg-800x571.png");
      height: auto;
      width: 55%;
      border-radius: 40px;
      box-shadow: -3px 3px 6px #00000029;
      // box-shadow: none;
      // position: relative;
      background-color: $bright-gray;
    }
    .form {
      padding: 30px 30px 10px 30px;
      background-color: $athens-gray-alt;
      width: 100%;
      height: 100%;
      border-radius: 40px;
      display: flex;
      flex-direction: column;
      @media (#{map-get($grid-breakpoints, two)}) {
        height: auto;
        width: 75%;
      }
      .form-group {
        width: 100%;
        &.submit {
          margin: rem-calc(11 0 17);
        }
        &.input {
          margin-bottom: rem-calc(7);
          input {
            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              font-size: rem-calc(18);
              font-weight: $font-weight-medium;
              color: $dusty-gray;
              line-height: 22px;
            }
            &::-moz-placeholder {
              /* Firefox 19+ */
              font-size: rem-calc(18);
              font-weight: $font-weight-medium;
              color: $dusty-gray;
              line-height: 22px;
            }
            &:-ms-input-placeholder {
              /* IE 10+ */
              font-size: rem-calc(18);
              font-weight: $font-weight-medium;
              color: $dusty-gray;
              line-height: 22px;
            }
            &:-moz-placeholder {
              /* Firefox 18- */
              font-size: rem-calc(18);
              font-weight: $font-weight-medium;
              color: $dusty-gray;
              line-height: 22px;
            }
          }
        }
        input,
        .btn {
          width: 100%;
          border-radius: 10px;
          &.btn-sso {
            background-image: linear-gradient(
              to right,
              $secondary-color,
              $primary-color
            );
          }
        }
        .link {
          color: $gray;
          font-size: rem-calc(12);
          font-weight: $font-weight-light;
          line-height: rem-calc(16);
          text-decoration: none;
          text-transform: uppercase;

          span {
            font-weight: $font-weight-medium;
          }
        }
      }
      .server-error {
        margin-bottom: 10px;
      }
      .success-message {
        color: $success-color;
        margin-bottom: 10px;
      }
    }
  }

  + .screen-modal {
    position: fixed;
  }

  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px; 
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  /* Background colour of an input field with value */
  .otp-input.is-complete {
    background-color: #e4e4e4;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
