.main-single-page {
  position: relative;

  .open-main-menu {
    display: block;
    padding: 0;
    position: absolute;
    top: 0;
    height: rem-calc(44);
    left: 0;
    width: rem-calc(44);

    .svg-icon {
      align-items: center;
      display: flex;
      height: rem-calc(44);
      justify-content: center;
      width: rem-calc(44);
    }

    svg {
      fill: $primary-color;
      height: rem-calc(20);
      width: rem-calc(20);
    }

    @media (#{map-get($grid-breakpoints, one)}) {
      display: none;
    }
  }
}
