.page-title-container {
  background-color: $primary-color;
  box-shadow: rem-calc(0 0 20) rgba($violet, 0.26);
  color: $white;
  margin: 0;
  padding: rem-calc(40 0 67);

  .svg-icon {
    display: block;
    height: rem-calc(75);
    margin: 0 auto rem-calc(5);
    width: rem-calc(94);
  }

  svg {
    fill: $white;
    height: rem-calc(75);
    width: rem-calc(94);
  }

  .page-title {
    display: block;
    font-size: rem-calc(36);
    line-height: rem-calc(48);
    margin: 0;
  }
}
