.action-screen {
  .top-header-menu {
    background-color: $primary-color;
    color: $white;
  }

  .svg-icon {
    fill: $white
  }
}

.actions-top {
  background-color: rgba($primary-color, .8);
  margin-bottom: rem-calc(52);
  padding-bottom: rem-calc(37);
  position: relative;
}

.action-location-info {
  align-items: center;
  color: $white;
  display: flex;
  padding: rem-calc(0 20);
  width: 100%;

  .svg-icon {
    align-items: center;
    background-color: $white;
    box-shadow: rem-calc(0 3 6) rgba($black, .16);
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: rem-calc(80);
    justify-content: center;
    margin-right: rem-calc(15);
    width: rem-calc(80);
  }

  svg {
    fill: $secondary-color;
    height: rem-calc(50);
    width: rem-calc(38);
  }

  .location-info {
    font-size: rem-calc(16);
    line-height: rem-calc(21);
  }
}

.actions-check-in-out {
  display: flex;
  justify-content: center;
  padding: rem-calc(0 15);
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
  width: 100%;

  .check-btn {
    align-items: center;
    background-color: $white;
    box-shadow: 0px 10px 10px #00000033;
    border-radius: rem-calc(8);
    display: flex;
    font-size: rem-calc(20);
    font-weight: $font-weight-bold;
    letter-spacing: rem-calc(-0.48);
    line-height: rem-calc(26);
    flex-direction: column;
    justify-content: center;
    padding: rem-calc(10);
    text-transform: uppercase;
    width: rem-calc(140);

    span {
      display: block;
      margin-top: rem-calc(6);
    }

    .svg-icon,
    svg {
      height: rem-calc(32);
      width: rem-calc(28);
    }

    &:last-child {
      margin-left: rem-calc(20);
    }

    &.check-in {
      color: $primary-color;

      svg {
        fill: $primary-color;
      }
    }

    &.check-out {
      background-color: $secondary-color;
      color: $white;

      svg {
        fill: $white;
      }
    }
  }
}

.actions-time-left {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: rem-calc(0 0 34 0);

  &.processing {
    flex-direction: column;

    .label {
      margin: 0;
      font-size: rem-calc(20);
      line-height: rem-calc(26);
      width: auto
    }
  }

  .label {
    color: rgba($dusty-gray, .6);
    font-size: rem-calc(20);
    font-weight: $font-weight-bold;
    line-height: rem-calc(26);
    margin-right: rem-calc(20);
    text-transform: uppercase;
    width: rem-calc(46);

    &.active {
      color: $dusty-gray;
    }
  }

  .time {
    color: rgba($dusty-gray, .6);
    font-size: rem-calc(38);
    font-weight: $font-weight-bold;
    line-height: rem-calc(50);

    &.active {
      color: $primary-color;
    }
  }
}

.actions-extend-time-title {
  align-items: center;
  display: flex;
  color: $bright-gray;
  font-size: rem-calc(25);
  font-weight: $font-weight-semi-bold;
  height: rem-calc(114);
  justify-content: center;
  line-height: rem-calc(33);
  margin-bottom: rem-calc(28);
  position: relative;
  text-transform: uppercase;

  .svg-icon,
  svg {
    fill: $secondary-color;
    height: rem-calc(114);
    left: 50%;
    opacity: 0.2;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: rem-calc(114);
  }
}

.extend-time-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .extend-time-item {
    max-width: 1/3 * 100%;
    margin-bottom: rem-calc(16);
    width: 1/3 * 100%;
  }

  .extend-time-button {
    background-color: $white;
    box-shadow: rem-calc(0 3 6) rgba($black, .16);
    border-radius: 50%;
    color: rgba($dusty-gray, .6);
    display: block;
    font-size: rem-calc(18);
    font-weight: $font-weight-semi-bold;
    height: rem-calc(78);
    line-height: rem-calc(24);
    margin: auto;
    padding: 0;
    position: relative;
    width: rem-calc(78);

    &::before {
      bottom: 50%;
      box-shadow: inset rem-calc(-4 -4 0 0) rgba($primary-color, 0.1);
      content: '';
      display: block;
      height: rem-calc(32);
      position: absolute;
      right: 50%;
      transform: translate3d(rem-calc(2), rem-calc(2), 0);
      width: rem-calc(23);
    }

    &::after {
      bottom: 0;
      box-shadow: inset rem-calc(0 0 0 3) rgba($dusty-gray, .6);
      border-radius: 50%;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &.selected {
      background-color: $primary-color;
      color: $white;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &.active {
    .extend-time-button {
      color: $primary-color;

      &::after {
        box-shadow: inset rem-calc(0 0 0 3) $dusty-gray;
      }
    }
  }
}
