.display-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #EFEFF4 0% 0% no-repeat padding-box;
  padding: 2.5%;
}

.template-error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  .resource {
    font: normal normal 500 3vh Roboto;
    display: flex;
    margin-bottom: 2vh;
  }

  .message {
    font: normal normal 350 3vh Roboto;
    display: flex;
    margin-bottom: 10vh;
  }

  img {
    width: 10vw;
    margin-bottom: 4vw;
  }
}

.template-1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .left {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5%;
  }

  .resource, .meeting {
    height: 33%;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4vh;
    border: 0.5px solid #707070;
    box-shadow: 0px 13px 16px #00000029;
  }

  .resource {
    height: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
  }

  .name {
    text-align: left;
    font: normal normal 400 7vh Roboto;
    letter-spacing: -1.44px;
    color: #373F4E;
    opacity: 1;
    line-height: normal;
  }

  .availability {
    text-align: left;
    font: normal normal 350 5vh Roboto;
    letter-spacing: -1.68px;
    color: #737373;
    opacity: 1;
    line-height: normal;
  }

  .meeting {
    height: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1.5%;
    padding-left: 5%;
    box-sizing: border-box;
  }

  .info {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 5%;

    justify-content: center;
  }

  .subject {
    text-align: left;
    font: normal normal 400 5.5vh Roboto;
    letter-spacing: -1.44px;
    color: #373F4E;
    opacity: 1;
    line-height: normal;
  }

  .host {
    text-align: left;
    font: normal normal 350 4vh Roboto;
    letter-spacing: -1.68px;
    color: #999999;
    opacity: 1;
    line-height: normal;
  }

  .times {
      aspect-ratio : 1 / 1;
      height: 100%;
      background: #EFEFF4 0% 0% no-repeat padding-box;
      border: 0.5px solid #707070;
      border-radius: 3vh;
      opacity: 1;
      flex-direction: column;
  }

  .clock {
    width: 100%;
    height: 60%;
    fill: #737373;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clock svg {
    width: 10vh;
  }

  .time {
    width: 100%;
    height: 20%;
    text-align: center;
    font: normal normal normal 3.5vh Roboto;
    letter-spacing: -0.8px;
    color: #A3A3A3;
    opacity: 1;
  }

  .options {
    height: 33%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 2vw;
  }

  .logo {
    aspect-ratio : 1 / 1;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 220px;
  }

  .company-logo {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .company-logo img {
    height: 80%;
  }

  .clebex-logo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clebex-logo img {
    height: 80%;
  }

  .powered-by-clebex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
  }

  .powered-by-clebex span {
    margin-right: 10px;
    font-size: 1vh;
  }

  .powered-by-clebex img {
    width: 10vh;
  }

  .next-meetings {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    opacity: 1;
    overflow: hidden;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }

  .next-meetings-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 1vh;
    gap: 1vh;
    padding: 0 2vh 0 2vh;
  }

  .next-meetings-column {
    text-align: left;
    font: normal normal 400 2.4vh Roboto;
    letter-spacing: -1.44px;
    color: #373F4E;
    opacity: 1;
    line-height: normal;
    padding: 1vh;
    box-sizing: border-box;
  }

  .next-meeting-date, .next-meeting-time {
    flex: 0 0 auto;
    flex-shrink: 0;
  }

  .next-meeting-host, .next-meeting-subject {
    flex: 1 0 auto;
    flex-shrink: 0;
  }

  .option-container {
    display: flex;
    flex-direction: column;
  }

  .map, .calendar, .video, .videoconference {
    background: #FAFAFAC4 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    opacity: 1;
    aspect-ratio : 1 / 1;
    height: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    fill: #737373;
    cursor: pointer;
  }

  .option-text {
    font: normal normal normal 2vh Roboto;
    letter-spacing: -0.72px;
    color: #999999;
    opacity: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1vh;
  }

  .right {
    height: 100%;
    padding-left: 2.5%;
  }

  .status {
    background: #19AA00 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    opacity: 1;
    height: 100%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .status-icon {
    width: 100%;
    flex: 1;
    fill: white;
    display: flex;
    justify-content: center;
  }

  .accepted {
    background: #19AA00 0% 0% no-repeat padding-box;
  }

  .checkin {
    background: #F0BE00 0% 0% no-repeat padding-box;
  }

  .declined {
    background: #FF3232 0% 0% no-repeat padding-box;
  }

  .status-icon svg {
    margin-top: 20%;
    width: 80%;
  }

  .qr-code {
    aspect-ratio : 1 / 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 2;
  }

  .qr-code img {
    width: 90%;
  }

}

.template-2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .upper {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1vw
  }

  .resource-meeting {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 3%;
  }

  .resource, .meeting {
    height: 50%;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4vh;
    border: 0.5px solid #707070;
    box-shadow: 0px 13px 16px #00000029;
  }

  .resource {
    margin-top: 0px;
    margin-bottom: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
  }

  .name {
    text-align: left;
    font: normal normal 400 7vh Roboto;
    letter-spacing: -1.44px;
    color: #373F4E;
    opacity: 1;
    line-height: normal;
  }

  .availability {
    text-align: left;
    font: normal normal 350 5vh Roboto;
    letter-spacing: -1.68px;
    color: #737373;
    opacity: 1;
    line-height: normal;
  }

  .meeting {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2%;
    margin-bottom: 3%;
    margin-top: 1%;
    padding-left: 5%;
  }

  .info {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 5%;

    justify-content: center;
  }

  .subject {
    text-align: left;
    font: normal normal 400 5.5vh Roboto;
    letter-spacing: -1.44px;
    color: #373F4E;
    opacity: 1;
    line-height: normal;
  }

  .host {
    text-align: left;
    font: normal normal 350 4vh Roboto;
    letter-spacing: -1.68px;
    color: #999999;
    opacity: 1;
    line-height: normal;
  }

  .times {
    aspect-ratio : 1 / 1;
    height: 100%;
    background: #EFEFF4 0% 0% no-repeat padding-box;
    border: 0.5px solid #707070;
    border-radius: 3vh;
    opacity: 1;
    flex-direction: column;
  }

  .clock {
    width: 100%;
    height: 60%;
    fill: #737373;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clock svg {
    width: 10vh;
  }

  .time {
    width: 100%;
    height: 20%;
    text-align: center;
    font: normal normal normal 3.5vh Roboto;
    letter-spacing: -0.8px;
    color: #A3A3A3;
    opacity: 1;
  }

  .logo {
    aspect-ratio : 1 / 1;
    height: 104%;
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    opacity: 1;
    position: relative;
    top: -0.4vh;
    overflow: hidden;
  }

  .company-logo {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .company-logo img {
    height: 80%;
  }

  .clebex-logo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clebex-logo img {
    height: 80%;
  }

  .powered-by-clebex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
  }

  .powered-by-clebex span {
    margin-right: 10px;
    font-size: 1vh;
  }

  .powered-by-clebex img {
    width: 10vh;
  }

  .options {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1%;
    align-items: end;
    min-width: auto;
    overflow: hidden;
  }

  .option {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    width: 16vh;
    height: auto;
    aspect-ratio: 1/1;
  }

  .option svg {
    background: #FAFAFAC4 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    opacity: 1;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    fill: #737373;
    padding: 20%;
    width: 80%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  .option span {
    font: normal normal normal 2vh Roboto;
    letter-spacing: -0.72px;
    color: #999999;
    opacity: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.3vh;
    overflow: hidden;
  }

  .lower {
    width: 100%;
    height: 27%;
  }

  .status {
    background: #19AA00 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    opacity: 1;
    height: 100%;
    width: 100%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: row;
  }

  .status-icon {
    height: 100%;
    flex: 1;
    fill: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .accepted {
    background: #19AA00 0% 0% no-repeat padding-box;
  }

  .checkin {
    background: #F0BE00 0% 0% no-repeat padding-box;
  }

  .declined {
    background: #FF3232 0% 0% no-repeat padding-box;
  }

  .status-icon svg {
    width: 20vh;
  }

  .qr-code {
    aspect-ratio : 1 / 1;
    height: 104%;
    margin-bottom: 2%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    position: relative;
    top: -0.4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 2;
  }

  .qr-code img {
    width: 90%;
  }
}

.template-5 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3%;

  .upper {
    width: 100%;
    display: flex;
    height: 66%;
  }

  .left {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4%;
  }

  .resource, .meeting {
    height: 48%;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4vh;
    border: 0.5px solid #707070;
    box-shadow: 0px 13px 16px #00000029;
  }

  .resource {
    height: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
  }

  .name {
    text-align: left;
    font: normal normal 400 7vh Roboto;
    letter-spacing: -1.44px;
    color: #373F4E;
    opacity: 1;
    line-height: normal;
  }

  .availability {
    text-align: left;
    font: normal normal 350 5vh Roboto;
    letter-spacing: -1.68px;
    color: #737373;
    opacity: 1;
    line-height: normal;
  }

  .meeting {
    height: 48%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1.5%;
    padding-left: 5%;
    box-sizing: border-box;
  }

  .info {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 5%;
    justify-content: center;
  }

  .subject {
    text-align: left;
    font: normal normal 400 5.5vh Roboto;
    letter-spacing: -1.44px;
    color: #373F4E;
    opacity: 1;
    line-height: normal;
  }

  .host {
    text-align: left;
    font: normal normal 350 4vh Roboto;
    letter-spacing: -1.68px;
    color: #999999;
    opacity: 1;
    line-height: normal;
  }

  .times {
      aspect-ratio : 1 / 1;
      height: 100%;
      background: #EFEFF4 0% 0% no-repeat padding-box;
      border: 0.5px solid #707070;
      border-radius: 3vh;
      opacity: 1;
      flex-direction: column;
  }

  .clock {
    width: 100%;
    height: 60%;
    fill: #737373;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clock svg {
    width: 10vh;
  }

  .time {
    width: 100%;
    height: 20%;
    text-align: center;
    font: normal normal normal 3.5vh Roboto;
    letter-spacing: -0.8px;
    color: #A3A3A3;
    opacity: 1;
  }

  .lower {
    height: 33%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .logo {
    aspect-ratio : 1 / 1;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 220px;
  }

  .company-logo {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .company-logo img {
    height: 80%;
  }

  .clebex-logo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clebex-logo img {
    height: 80%;
  }

  .powered-by-clebex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
  }

  .powered-by-clebex span {
    margin-right: 10px;
    font-size: 1vh;
  }

  .powered-by-clebex img {
    width: 10vh;
  }

  .next-meetings {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    opacity: 1;
    overflow: hidden;
    margin-left: 2%;
    align-items: center;
    justify-content: center;
  }

  .next-meetings-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 1vh;
    gap: 1vh;
    padding: 0 2vh 0 2vh;
  }

  .next-meetings-column {
    text-align: left;
    font: normal normal 400 2.4vh Roboto;
    letter-spacing: -1.44px;
    color: #373F4E;
    opacity: 1;
    line-height: normal;
    padding: 1vh;
    box-sizing: border-box;
  }

  .next-meeting-date, .next-meeting-time {
    flex: 0 0 auto;
    flex-shrink: 0;
  }

  .next-meeting-host, .next-meeting-subject {
    flex: 1 0 auto;
    flex-shrink: 0;
  }

  .right {
    height: 100%;
    padding-left: 2%;
  }

  .status {
    background: #19AA00 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    opacity: 1;
    height: 100%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .status-icon {
    width: 100%;
    flex: 1;
    fill: white;
    display: flex;
    justify-content: center;
  }

  .accepted {
    background: #19AA00 0% 0% no-repeat padding-box;
  }

  .checkin {
    background: #F0BE00 0% 0% no-repeat padding-box;
  }

  .declined {
    background: #FF3232 0% 0% no-repeat padding-box;
  }

  .status-icon svg {
    margin-top: 20%;
    width: 80%;
  }

  .qr-code {
    aspect-ratio : 1 / 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 16px #00000029;
    border: 0.5px solid #707070;
    border-radius: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 2;
  }

  .qr-code img {
    width: 90%;
  }

}

.template-map {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  visibility: hidden;
}

.back-to-main-page {
  background-color: white;
  cursor: pointer;
  position: absolute;
  top: 2vh;
  left: 2vw;
  border-radius: 50%;
  font: normal normal bold 2.5vh Roboto;
  z-index: 1000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  fill: #999999;
  width: 6.5vh;
  height: 6.5vh;
}

.map-date-time {
    width: 25vh;
    height: 6.5vh !important;
    background-color: #eeeef0;
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2vh;
    right: 2vw;
    height: 6vh;
    border-radius: 2vh;
    overflow: hidden;
    font: normal normal 400 2.7vh Roboto !important;
	  border-radius: 3vh;
    padding-top: 0.5vh;
}

.map-date {
  color: #737373;
  font-size: 1.8vh;
  font-weight: 450;
  margin-bottom: 0.2vh;
}

.map-time {
  font-size: 1.8vh;
  font-weight: 450;
  margin-bottom: 0.2vh;
}

.template-video {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  z-index: 100000 !important;
}

.back-to-main-page-div {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100000 !important;
}

.template-calendar {
  width: 100%;
  height: 100%;
  position: absolute;
  visibility: hidden;
  background: #EFEFF4;
  display: flex;
  flex-direction: column;
  padding: 3vh;
  box-sizing: border-box;
}

.template-calendar p {
  width: 50%;
  text-align: center;
}

.display-calendar-header {
  display: flex;
  flex-direction: row;
  font: normal normal normal 3vh Roboto;
  letter-spacing: -1.08px;
  color: #999999;
  min-height: 7vh;
}

.display-calendar-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 2vh;
}

.display-calendar-table {
  background: #241B1B1A 0% 0% no-repeat padding-box;
  border-radius: 2vh;
  overflow: hidden; 
  flex: 1;
  box-sizing: border-box;
  border: 1px solid #2913133e;
  display: flex;
  flex-direction: column;
}

.display-calendar-table-no-meeting {
  padding-left: 2%;
  padding-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.display-calendar-table p {
  text-align: left;
  padding: 2px;
  width: 100%;
  z-index: 10;
}

.display-calendar-table-meeting {
  background-color: white;
  padding-left: 2%;
}

.display-calendar-times {
  font-size: 1.7vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.display-calendar-current {
  font-size: 1.2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1%;
}

.display-calendar-subject {
  font-size: 1.3vh;
}

.display-calendar-host {
  font-size: 1.3vh;
  color: gray;
}

.display-calendar-item {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.display-calendar-item-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 2%;
}

.display-calendar-item-status-busy {
  width: 5%;
  height: 100%;
  background: #FF3232 0% 0% no-repeat padding-box;
}

.display-calendar-item-status-checkin {
  width: 5%;
  height: 100%;
  background: #F0BE00 0% 0% no-repeat padding-box;
}

.display-calendar-item-start {
  border-top: 1px solid #2913133e !important;
}

.display-calendar-item-end {
  box-shadow: 0px 13px 16px #00000020 !important;
}

.calendar-qr-code {
  position: absolute;
  top: 1%;
  right: 1%;
  width: 13vh;
  padding: 0.5%;
  background-color: white;
  border-radius: 10%;
  display: flex;
  flex-direction: row;
  border-left: 1.5vw solid green;
  box-shadow: 0px 13px 16px #00000029;
}

.calendar-qr-code-checkin {
  border-left: 1.5vw solid #F0BE00;
}

.calendar-qr-code-busy {
  border-left: 1.5vw solid #FF3232;
}

.template-videoconference {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;

  .back-to-main-page {
    background-color: white;
    cursor: pointer;
    position: absolute;
    top: 2vh;
    left: 2vw;
    border-radius: 50%;
    font: normal normal bold 2.5vh Roboto;
    z-index: 1000;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    fill: #999999;
    width: 6.5vh;
    height: 6.5vh;
  }

  .back-to-main-page-div {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100000 !important;
  }

}

.status-white {
  position: absolute; 
  bottom: 0px; 
  width: 100%; 
  height: 100px; 
  background-color: white;
  z-index: 1;
}