.select-time-picker {
  display: flex;
  justify-content: space-between;
  height: rem-calc(258);
  .hours-placeholder {
    font-size: rem-calc(22);
    color: $dusty-gray;
    position: absolute;
    top: rem-calc(20);
    &:not(.second) {
      left: rem-calc(23) !important;
    }
    &.second {
      right: rem-calc(15) !important;
    }
    .svg-icon {
      --color-1: #{$doveGray};
      --color-2: #{$silverChalice};
    }
  }
  .list-of-time-slots {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 75%;

    &.hidden {
      display: none;
    }

    &.list-of-minutes-wrapper {
      border: 1px solid $dusty-gray;
      display: block;
      width: 25%;
      margin: rem-calc(8 0);
      &:not(.second) {
        margin-right: rem-calc(5);
        border-left: none;
        border-radius: rem-calc(0 22 22 0);
      }
      &.second {
        margin-left: rem-calc(5);
        border-right: none;
        border-radius: rem-calc(22 0 0 22);
      }
      .select-time-button-wrapper {
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
        width: 100%;
      }
      .list-of-minutes {
        margin-top: rem-calc(42);
        height: rem-calc(156);
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
        .selected {
          background-color: rgba($secondary-color, 0.7);
        }
      }
    }
    &.list-of-hours {
      align-self: center;
    }
  }

  .select-time-button-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: rem-calc(6 0);
    width: 25%;
  }

  .select-time-btn {
    border: 1px solid $dusty-gray;
    border-radius: rem-calc(23);
    color: $dusty-gray;
    font-size: rem-calc(14);
    height: rem-calc(30);
    line-height: rem-calc(22);
    letter-spacing: -0.34px;
    padding: 0;
    width: rem-calc(55);

    &.selected {
      &:not(.no-range) {
        background-color: rgba($primary-color, 0.3);
        border-color: $dusty-gray;
      }

      &.first,
      &.last {
        background-color: rgba($primary-color, 0.7);
        color: $white;
      }
    }

    &.small {
      font-size: rem-calc(11);
    }
  }
}
