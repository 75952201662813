.steps-indicator {
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  .step {
    background-color: rgba($white, 0.5);
    border-radius: 50%;
    height: rem-calc(10);
    margin: rem-calc(0 5);
    width: rem-calc(10);

    &.active {
      background-color: $white;
    }
  }
}
