.language-screen {
  position: relative;
}

.language-group {
  .language-group-identifier  {
    background-color: rgba($alabaster, 0.82);
    font-size: rem-calc(17);
    font-weight: $font-weight-semi-bold;
    letter-spacing: -0.41px;
    line-height: rem-calc(22);
    padding: rem-calc(3 16);
  }

  .languages-list {
    padding: rem-calc(0 16);
  }

  .language {
    border-bottom: 1px solid $french-gray;

    &:last-child {
      border-bottom: 0;
    }
  }

  .btn-language {
    display: block;
    font-size: rem-calc(17);
    letter-spacing: -0.41px;
    line-height: rem-calc(22);
    padding: rem-calc(11 0);
    text-align: left;
    width: 100%;
  }
}

.languages-nav {
  align-items: center;
  background-color: rgba($white, 0.5);
  bottom: 0;
  display: flex;
  position: absolute;
  text-align: center;
  right: 0;
  top: rem-calc(100);
  width: rem-calc(16);
}
