.time-zone-screen {
  background-color: $athens-gray;
}

.set-time-zone {
  background-color: $white;
  padding: rem-calc(0 16);
  font-size: rem-calc(17);
  letter-spacing: rem-calc(-0.41);
  line-height: rem-calc(22);
  margin-top: rem-calc(30);

  li {
    align-items: center;
    border-bottom: 1px solid rgba($tuna, 0.29);
    display: flex;
    height: rem-calc(44);
    justify-content: space-between;
    width: 100%;

    &:last-child {
      border-bottom: 0;
    }
  }

  .selected-time-zone {
    color: $dusty-gray;

    &.btn {
      box-shadow: none;
      color: $bright-gray;
      display: block;
      font-size: rem-calc(17);
      font-weight: $font-weight-regular;
      height: rem-calc(44);
      letter-spacing: rem-calc(-0.41);
      padding: rem-calc(0 44 0 0);
      position: relative;
      text-align: left;
      text-transform: none;
      width: 100%;

      .svg-icon,
      svg {
        fill: #3C3C434D;
        height: rem-calc(16);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: rem-calc(16);
      }
    }
  }
}

.time-zone-list {
  background-color: $white;
  padding: rem-calc(0 16);

  .time-zone {
    border-bottom: 1px solid rgba($tuna, 0.29);
    height: rem-calc(44);
  }

  .select-time-zone-btn {
    color: $black;
    display: block;
    height: rem-calc(44);
    font-size: rem-calc(17);
    padding: 0;
    letter-spacing: rem-calc(-0.41);
    line-height: rem-calc(22);
    text-align: left;
    width: 100%;
  }
}
