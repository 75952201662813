.permissions-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  text-align: center;

  .page-title-container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
  }

  .info {
    font-size: rem-calc(12);
    line-height: rem-calc(16);
    margin: 0 auto;
    max-width: 100%;
    width: rem-calc(314);
  }

  .btn:not(.tiny) {
    line-height: rem-calc(28);
    max-width: 100%;
    margin-top: auto;
    width: rem-calc(280);
  }

  .tiny {
    bottom: rem-calc(32);
    color: $white;
    height: auto;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  .steps-indicator-wrapper {
    align-items: flex-end;
    background-color: $primary-color;
    bottom: 0;
    display: flex;
    height: rem-calc(67);
    justify-content: center;
    padding-bottom: rem-calc(10);
    position: absolute;
    width: 100%;
  }
}
