.clebex-version-row {
  display: flex;
}
.clebex-version-push-right {
  text-align: right;
}
.app-versions {
  .action-btn {
    &:not(.selectable) {
      color: $dusty-gray;
    }
    &.delete-app {
      padding-right: 24px;
    }
  }
  .clebex-item-section .clebex-item-content-wrapper .label {
    line-height: inherit;
  }
  .clebex-item-section {
    &.clebex-item-section-borderless {
      border-bottom: none;
    }
    .clebex-item-section-item {
      .clebex-item-content-wrapper {
        .checkbox {
          width: auto;
          margin-right: -16px;
          label {
            min-height: 40px;
          }
        }
        justify-content: space-between;
        padding: 5px 0;
        .clebex-version-row-wrapper {
          flex-grow: 1;
          overflow: hidden;
          .clebex-version-row {
            justify-content: space-between;
            .clebex-app-version-number {
              font-size: 13px;
              color: $black;
              font-weight: $font-weight-medium;
              margin-bottom: 5px;
            }
            .clebex-app-version-date {
              font-size: 13px;
              color: $dusty-gray;
              font-weight: $font-weight-medium;
              letter-spacing: -0.1px;
              .clebex-version-push-right {
                margin-left: auto;
              }
            }
          }
          .clebex-app-version-description {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 13px;
            color: $black;
            letter-spacing: -0.1px;
            display: block;
            line-height: 14px;
            &.more-description {
              white-space: pre-wrap;
            }
          }
          .clebex-app-version-more {
            font-size: 13px;
            color: $primary-color;
            margin-top: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.app-version {
  #versionDescription {
    height: 200px;
    resize: none;
    border: none;
    -webkit-text-fill-color: $dusty-gray;
  }
  input {
    border: none !important;
    -webkit-text-fill-color: $dusty-gray;
  }
  .clebex-item-section .clebex-item-section-item .error-message {
    display: block;
    width: auto;
    top: 30px;
    right: 0;
    left: auto;
    background-color: transparent;
  }
}
.add-version {
  .app-versions-header {
    a {
      text-decoration: none;
    }
  }
  #versionDescription {
    height: 200px;
    resize: none;
    border: none;
  }
  input {
    border: none !important;
  }
  .clebex-item-section .clebex-item-section-item .error-message {
    display: block;
    width: auto;
    top: 30px;
    right: 0;
    left: auto;
    background-color: transparent;
  }
}
.about {
  .clebex-item-section {
    .clebex-item-section-item {
      .clebex-item-content-wrapper {
        .checkbox {
          width: auto;
          margin-right: -16px;
          label {
            min-height: 40px;
          }
        }
      }
    }
  }
  .action-btn {
    &:not(.selectable) {
      color: $dusty-gray;
    }
    &.delete-app {
      padding-right: 24px;
    }
  }
}
