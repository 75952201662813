.profile-data-list {
  > .label {
    background-color: $athens-gray;
    border-bottom: 1px solid rgba($tuna, 0.29);
    color: rgba($tuna, 0.6);
    font-size: rem-calc(13);
    padding: rem-calc(13 16 4);
    letter-spacing: rem-calc(-0.1);
    line-height: rem-calc(21);
    text-transform: uppercase;
  }

  > .info {
    border-bottom: 1px solid $french-gray;
    font-size: rem-calc(17);
    line-height: rem-calc(22);
    padding: rem-calc(0 16);

    [href],
    button:not([data-skip]) {
      display: block;
      padding: rem-calc(11 24 11 0);
      position: relative;
      text-decoration: none;
      width: 100%;

      .svg-icon,
      svg {
        fill: rgba($tuna, 0.3);
        height: rem-calc(26);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate3d(rem-calc(4), -50%, 0);
        width: rem-calc(26);
      }
    }

    .label {
      color: $dusty-gray;
      text-align: left;

      [href],
      button {
        color: $dusty-gray;
      }

      span {
        display: block;
        padding: rem-calc(11 0);
      }

      &:only-child {
        width: 100%;
      }
    }

    .data {
      color: $bright-gray;
      text-align: right;
      padding-left: rem-calc(16);

      [href],
      button {
        color: $bright-gray;
      }

      span {
        display: block;
        padding: rem-calc(11 0);
      }
    }

    &.editing {
      background-color: $athens-gray;
      padding-left: 0;
      padding-right: 0;

      .submit {
        margin-bottom: 0;
      }
    }
  }

  .profile-data-definition-list {
    align-items: center;
    border-bottom: 1px solid $french-gray;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:last-child {
      border-bottom: 0;
    }

    .data {
      input {
        text-align: right;
      }
    }
  }
}

.clebex-edit-done-svg {
  cursor: pointer;
}

.clebex-profile-photo-drag-drop-label {
  @media (#{map-get($grid-breakpoints, one)}) {
    display: block;
  }

  display: none;
  color: $bright-gray;
  text-align: center;
  margin-top: rem-calc(24);
  font-size: rem-calc(15);
}

.profile-photo-header-wrapper {
  align-items: center;
  background: transparent;
  box-shadow: none;
  border: none;
  display: flex;
  height: rem-calc(48);
  justify-content: center;
  text-decoration: none;

  .photo-wrapper {
    align-items: center;
    background-color: $primary-color-lighter;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    height: rem-calc(36);
    justify-content: center;
    width: rem-calc(36);
    border-radius: 50%;
    margin: rem-calc(4);
  }
}

.clebex-cropper-wrapper {
  position: relative;

  .clebex-photo-file-input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    &.show {
      z-index: 2;
    }
  }
}

.clebex-photo-selection-wrapper {
  background-color: $transparent-black;
  padding: rem-calc(32 20 16 20);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .cropper {
    height: rem-calc(335);
  }

  .clebex-photo-selection-type {
    background-color: #ffffff;
    font-size: rem-calc(17);
    line-height: rem-calc(22);
    height: rem-calc(45);
    padding-left: rem-calc(16);
    padding-right: rem-calc(16);
    line-height: rem-calc(45);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.clebex-take-photo {
      border-top-left-radius: rem-calc(8);
      border-top-right-radius: rem-calc(8);
      border-bottom: 1px solid $transparent-black;

      .svg-icon {
        svg {
          opacity: 0.8;
          height: rem-calc(20);
          width: rem-calc(20);
        }
      }
    }

    &.clebex-choose-existing-photo {
      &.mobile {
        border-top-left-radius: rem-calc(8);
        border-top-right-radius: rem-calc(8);
      }

      &:not(.has-photo) {
        border-bottom-left-radius: rem-calc(8);
        border-bottom-right-radius: rem-calc(8);
      }

      &.has-photo {
        border-bottom: 1px solid $transparent-black;
      }

      .svg-icon {
        border: 1px solid $transparent-black;
        border-radius: rem-calc(4);

        svg {
          height: rem-calc(12);
          width: rem-calc(12);
        }
      }
    }

    &.clebex-delete-existing-photo {
      border-bottom-left-radius: rem-calc(8);
      border-bottom-right-radius: rem-calc(8);
    }

    .svg-icon {
      color: inherit;
      align-items: center;
      background: transparent;
      box-shadow: none;
      border: none;
      display: flex;
      justify-content: center;
      text-decoration: none;
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }
}

.profile-assigned-resources {
  .clebex-item-section-item {
    a {
      text-decoration: none;
    }

    .clebex-item-content-wrapper {
      .label {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.break-list {
  color: $dusty-gray;
  font-size: rem-calc(14);
  list-style: none;
  margin: 0;
  padding: 0;

  .break-item {
    cursor: pointer;
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(8);

    &:not(:last-child) {
      border-bottom: 1px solid #cccccc;
    }

    .date-range {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      flex-grow: 1;
    }
  }

  .hours-and-weekdays {
    display: flex;
    justify-content: space-between;
    padding-bottom: rem-calc(8);
  }

  .weekdays {
    display: flex;
    > span {
      display: block;
      padding: rem-calc(0 2);
    }
  }

  .checkbox {
    &.tiny {
      &.alt {
        label {
          padding-left: rem-calc(24);

          &:before {
            left: 0;
          }

          .svg-icon {
            left: rem-calc(4);
          }
        }
      }
    }
    &.block {
      label {
        min-height: auto;
      }
    }

    label {
      color: $dusty-gray;
      font-size: rem-calc(14);
    }
  }
}
// Profile font size page test
.profile-screen,
.language-screen,
.time-zone-screen,
.country-screen,
.change-password-screen,
.edit-assigned-resource {
  .clebex-item-section {
    input,
    p,
    span:not(.day-button),
    dd,
    button:not(.day-button):not(.clebex-item-range-cta) {
      font-size: 15px !important;
    }
  }
  .vue-advanced-cropper__background,
  .vue-advanced-cropper__foreground {
    background: #578280;
    border: 1px solid #707070;
    border-radius: 16px;
  }

  .vue-advanced-cropper__image-wrapper {
    border: 1px solid transparent;
    border-radius: 16px;
  }
}
// Profile font size page test
.assigned-resource-break {
  button {
    font-size: 15px !important;
  }
}
.profile-screen {
  &.delegates-screen {
    &.full-width {
      flex: 1;
      container-type: inline-size;
      .delegates-body {
        position: relative;
        margin: rem-calc(10);
        @container (#{map-get($grid-breakpoints, two)}) {
          padding: rem-calc(5);
        }
        .clebex-item-content-wrapper {
          @container (#{map-get($grid-breakpoints, two)}) {
            align-items: center !important;
            flex-direction: row !important;
            padding: 0 !important;
            .label {
              padding: rem-calc(4);
            }
          }
        }
        .delegates-header {
          margin: rem-calc(10 0);
          display: none;

          @container (#{map-get($grid-breakpoints, two)}) {
            display: block;
          }

          .clebex-item-content-wrapper {
            .label {
              padding: 0;
              text-align: center;
              &:not(:last-child) {
                border-right: 1px solid $dusty-gray;
              }
            }
            @container (#{map-get($grid-breakpoints, two)}) {
              flex-direction: row;
              align-items: center;
              padding: 0;
            }
          }
        }
        .clebex-item-section-item {
          padding: 0 !important;
          .clebex-item-content-wrapper {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            padding: rem-calc(10 20);
            color: inherit;
            text-decoration: none;

            .checkbox.slide {
              margin-right: rem-calc(16);
            }

            .delegates-item-column {
              padding: rem-calc(0 10);
              flex: 1;
              display: flex;
              align-items: center;
              @container (#{map-get($grid-breakpoints, two)}) {
                align-items: center;
              }
              .column-name {
                font-weight: 500;
                margin-right: rem-calc(10);
                @container (#{map-get($grid-breakpoints, two)}) {
                  display: none;
                }
              }
              &:last-child {
                justify-content: space-between;
              }
              svg {
                &.success {
                  --color-1: #19aa00;
                }
                &.error {
                  --color-1: #ff3131;
                }
                &.warning {
                  --color-1: #f0be00;
                }
              }
              .checkbox.tiny.alt label > .svg-icon {
                left: rem-calc(3);
              }
              .declaration-arrow {
                position: absolute;
                right: rem-calc(10);
                top: 50%;
                transform: translate(0, -50%);
                @container (#{map-get($grid-breakpoints, two)}) {
                  position: inherit;
                  transform: none;
                }
              }
              .declaration-checkbox-wrapper {
                display: flex;
                align-items: center;
                position: absolute;
                left: rem-calc(10);
                top: 50%;
                transform: translate(0, -50%);
                @container (#{map-get($grid-breakpoints, two)}) {
                  position: inherit;
                  transform: none;
                  margin-right: rem-calc(10);
                }
              }
              .checkbox {
                width: auto;
                label {
                  background-color: white;
                  &::before {
                    left: 0;
                  }
                  padding: rem-calc(0 20 0 0);
                }
              }
            }
            &.grey-bg {
              background: $whiteIce;
              font-weight: 500;

              div {
                &:first-child {
                  width: rem-calc(55);
                }

                &:last-child {
                  width: rem-calc(40);
                }
              }
            }
          }
        }
      }
    }
  }
}
