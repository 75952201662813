.sync-event-list {
    .event-list-refresh-icon {
        fill: $gray !important;
        --color-2: $gray !important;
        &.in-progress {
            fill: $warning-color !important;
            --color-2: $warning-color !important;
        }
        &.done {
            fill: $success-color !important;
            --color-2: $success-color !important;
        }
        &.error {
            fill: $error-color !important;
            --color-2: $error-color !important;
        }
    }
}
