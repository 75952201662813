.clebex-item-section {
  background-color: $white;
  border-bottom: 1px solid rgba($tuna, 0.29);
  border-top: 1px solid rgba($tuna, 0.29);
  list-style: none;
  margin: rem-calc(27 16 4 16); // label will delete this top margin
  padding: 0;
  background-color: $white;
  border: none;
  border-radius: rem-calc(10);
  box-shadow: rem-calc(0 3 6) rgba($black, 0.16);

  &:last-child {
    margin-bottom: rem-calc(27);
  }
  &.no-border {
    border: none;
  }

  &.tiny-top-spacing {
    margin-top: rem-calc(15);
  }

  &.transparent {
    background-color: transparent;
  }

  .checkbox.tiny.alt label {
    color: $dusty-gray;
    font-size: 14px;

    .highlight,
    &.highlight {
      color: $bright-gray;
    }
  }

  &.pill {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    margin: rem-calc(27 0 27 0);
    .clebex-item-section-item {
      background: transparent;
      padding: rem-calc(0 16);
      margin-bottom: rem-calc(4);
      overflow: visible;

      &::after {
        display: none !important;
      }
    }

    .clebex-item-content-wrapper {
      background-color: $white;
      border-radius: rem-calc(10);
      box-shadow: rem-calc(0 3 6) rgba($black, 0.16);

      .label {
        color: $dusty-gray;
        display: inline-block;
        font-size: 15px;
        padding: rem-calc(4);

        &:first-child {
          padding-left: rem-calc(8);
        }

        &:only-child {
          padding: rem-calc(4 8);
        }

        .highlight {
          color: $bright-gray;
        }

        .declarable-icon {
          display: inline-block;
          vertical-align: middle;
          margin: rem-calc(0 2 0 0);
        }
        .second-row-declaration {
          color: $abbey;
          font-size: 14px;
        }
      }

      .inner-icons {
        align-items: center;
        display: flex;
        width: 100%;

        .svg-icon {
          margin: 0;
        }
      }

      .follow-up-icons:first-child {
        margin-bottom: auto;
      }
    }

    .clebex-item-section-label-inner {
      border-bottom: none;

      .clebex-label-content-wrapper {
        min-height: rem-calc(26);
      }
    }
  }

  .clebex-item-section-label-inner {
    border-bottom: 1px solid rgba($tuna, 0.29);
    color: $dusty-gray;
    font-size: rem-calc(15);
    line-height: 19/15 * 1em;
    letter-spacing: -0.36px;
    margin-left: rem-calc(16);

    &:only-child {
      border: none;
    }

    .clebex-label-content-wrapper {
      display: flex;
      align-items: center;
      min-height: rem-calc(40);
      padding: 0;
      text-decoration: none;
      width: 100%;

      .label {
        flex: 1;
      }
    }
  }

  .expanded {
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .clebex-item-section-item {
    text-decoration: none;
    position: relative;
    border-radius: rem-calc(10);

    &.transparent {
      background-color: transparent;
    }

    &:not(.collapsed):not(.no-separator):not(:last-child):after,
    &.semicollapsed:after {
      border-bottom: 1px solid rgba($tuna, 0.29);
      bottom: 0;
      content: "";
      display: block;
      left: rem-calc(16);
      pointer-events: all;
      position: absolute;
      right: 0;
    }

    &:last-child:after {
      border-bottom: 0 !important;
    }

    &.has-lead-icon {
      margin-left: rem-calc(40);
      overflow: visible;

      &::after {
        left: 0;
      }
    }

    &.no-bottom-border {
      &::after {
        border-bottom: 0 !important;
      }
    }

    .larger-label {
      font-size: rem-calc(17) !important;
    }

    &.light {
      .clebex-section-input-label {
        color: $dusty-gray;
      }
    }

    &.no-decoration-link {
      a {
        text-decoration: none;
      }
    }

    &.lead-icon-border::after {
      left: rem-calc(40);
    }

    &.slide-icon-border::after {
      left: rem-calc(68);
    }

    &:last-child::after {
      display: none;
    }

    &.collapsed .follow-up-icon-item.collapsable {
      svg {
        transform: rotate(90deg);
      }
    }

    &.expanded .follow-up-icon-item.collapsable {
      svg {
        transform: rotate(-90deg);
      }
    }

    .error-message {
      align-items: center;
      display: none;
      color: $error-color;
      height: rem-calc(27);
      left: 0;
      text-align: center;
      justify-content: center;
      position: absolute;
      top: 0;
      transform: translateY(-100%);
      width: 100%;
    }

    &.has-error {
      display: block;
      margin-top: rem-calc(27);

      .error-message {
        display: flex;
      }

      .clebex-section-input-label span {
        color: $error-color;
      }
    }

    &.hidden {
      display: none;
    }

    &.disabled {
      .label,
      .clebex-item-dl {
        opacity: 0.6;
      }
    }
  }

  .clebex-item-content-wrapper {
    display: flex;
    align-items: center;
    min-height: rem-calc(40);
    padding: 0;
    text-decoration: none;
    width: 100%;

    .lead-icon {
      .lead-icon-item {
        display: block;
        height: rem-calc(40);
        left: 0;
        margin: 0;
        padding: 0;
        top: 50%;
        position: absolute;
        transform: translate3d(-100%, -50%, 0);
        width: rem-calc(40);

        &.favorite svg {
          fill: $warning-color;
        }
        &.default svg {
          fill: $secondary-color;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }

      .svg-icon {
        align-items: center;
        display: flex;
        height: rem-calc(40);
        justify-content: center;
        max-width: rem-calc(30);
        width: auto;

        svg {
          fill: $dusty-gray;
          height: rem-calc(20);
          width: auto;
        }
      }

      + .label {
        padding-left: 0;
      }

      .slide {
        margin-left: rem-calc(10);
      }

      &.align-start {
        align-self: flex-start;
        margin-top: rem-calc(6);
      }
    }

    .label {
      color: $bright-gray;
      display: flex;
      flex: 1;
      font-size: rem-calc(17);
      line-height: rem-calc(22);
      letter-spacing: rem-calc(-0.41);
      overflow: hidden;
      padding: rem-calc(0 16);
      text-align: left;
      text-decoration: none;

      &.text {
        color: $bright-gray;
      }

      .text {
        color: $dusty-gray;

        .highlight {
          color: $bright-gray;
        }
      }

      &.no-left-padding {
        padding-left: 0;
      }

      &.gray {
        color: $dusty-gray;
      }

      .svg-icon {
        align-items: center;
        display: flex;
        height: rem-calc(20);
        justify-content: center;
        margin-left: rem-calc(4);
        width: rem-calc(20);

        svg {
          fill: $dusty-gray;
          height: rem-calc(20);
          width: rem-calc(20);
        }
      }
      &.small-text {
        font-size: rem-calc(15);
      }
    }
  }

  .follow-up-icons {
    justify-content: flex-end;
    display: flex;
    flex-shrink: 0;

    .svg-icon {
      align-items: center;
      display: flex;
      height: rem-calc(40);
      justify-content: center;
      width: rem-calc(40);

      svg {
        fill: $dusty-gray;
        height: rem-calc(20);
        width: rem-calc(20);
      }
    }

    .follow-up-icon-item {
      padding: 0;
      height: rem-calc(40);
      width: rem-calc(40);

      &.active svg {
        fill: $primary-color;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &.hidden {
        visibility: hidden;
      }
    }
  }
  .search-container {
    position: absolute;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    input {
      height: 100%;
      box-shadow: none;
      &:focus-visible {
        outline: none;
      }
    }
    button {
      position: absolute;
      right: rem-calc(-20);
    }
  }

  .clebex-section-input {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    padding: rem-calc(4 0);
    width: 100%;

    .clebex-section-input-label {
      color: $bright-gray;
      letter-spacing: rem-calc(-0.24);
      font-size: rem-calc(15);
      line-height: rem-calc(20);
      padding: rem-calc(0 16);

      span {
        color: $primary-color;
      }
    }

    [type="search"],
    [type="tel"],
    [type="time"],
    [type="url"],
    [type="color"],
    [type="text"],
    [type="password"],
    [type="date"],
    [type="datetime"],
    [type="datetime-local"],
    [type="month"],
    [type="week"],
    [type="email"],
    [type="number"],
    select,
    .text-label {
      background-color: transparent;
      box-shadow: none;
      color: $dusty-gray;
      font-size: rem-calc(16);
      font-weight: $font-weight-regular;
      height: rem-calc(23);
      letter-spacing: rem-calc(-0.41);
      line-height: rem-calc(22);
      padding: rem-calc(0 16);
      width: 100%;
      font-family: "Roboto";

      &::placeholder {
        color: $dusty-gray;
      }
      &:focus {
        outline: thin dotted;
      }
    }

    .text-label {
      height: auto;
    }

    .photo-wrapper {
      overflow: hidden;
      position: relative;
      .image-preview {
        align-items: center;
        background-color: $primary-color;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: rem-calc(100);
        width: rem-calc(100);

        &.photo-added {
          background-color: transparent;
        }
      }

      &.round .image-preview {
        border-radius: 50%;
      }

      [type="file"] {
        pointer-events: none;
        visibility: hidden;
        z-index: -1;
        position: absolute;
      }

      .initials {
        pointer-events: none;
        color: $white;
        font-size: rem-calc(24);
      }

      .image-buttons-list {
        display: flex;
        justify-content: space-between;
        padding-top: rem-calc(12);
        width: 100%;
      }

      .delete {
        margin-left: auto;
      }
    }

    .clebex-section-input-label {
      padding: rem-calc(0 16);
    }
  }

  .clebex-item-dl {
    align-items: center;
    display: flex;
    font-size: rem-calc(17);
    justify-content: space-between;
    line-height: rem-calc(22);
    letter-spacing: rem-calc(-0.41);
    padding: rem-calc(0 16);
    width: 100%;

    &.justify-start {
      justify-content: flex-start;

      .clebex-item-dd {
        flex: 1;
        margin-left: rem-calc(10);
      }
    }

    &.no-left-padding {
      padding-left: 0;
    }

    &.no-right-padding {
      padding-left: 0;
    }

    &.no-padding {
      padding-left: 0;
      padding-right: 0;
    }

    .clebex-item-dt {
      color: $dusty-gray;
      &.clebex-item-dt-black {
        color: $bright-gray;
      }

      [href],
      button,
      [type="submit"],
      .link {
        align-items: center;
        box-shadow: none;
        color: $bright-gray;
        display: flex;
        font-size: rem-calc(17);
        height: auto;
        justify-content: space-between;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(-0.41);
        padding: 0;
        text-decoration: none;
        width: auto;

        .svg-icon {
          transform: translateX(rem-calc(4));
        }

        .svg-icon,
        svg {
          fill: rgba($tuna, 0.3);
          height: rem-calc(20);
          width: rem-calc(20);
        }
      }
    }

    .clebex-item-dd {
      color: $bright-gray;
      &:not(.justify-start) {
        text-align: right;
      }

      [href],
      button,
      [type="submit"],
      .link {
        align-items: center;
        box-shadow: none;
        color: $bright-gray;
        display: flex;
        font-size: rem-calc(17);
        height: auto;
        justify-content: space-between;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(-0.41);
        padding: 0;
        text-decoration: none;
        width: auto;

        //.svg-icon {
        //  transform: translateX(rem-calc(4));
        //}

        .svg-icon,
        svg {
          fill: rgba($tuna, 0.3);
          height: rem-calc(20);
          width: rem-calc(20);
        }
      }
    }
  }

  .clebex-item-parent-list {
    .parent-name {
      color: $bright-gray;
      display: block;
      font-size: rem-calc(12);
      line-height: 1.5;
    }
  }

  .clebex-item-content-range {
    align-items: center;
    color: $dusty-gray;
    display: flex;
    font-size: rem-calc(17);
    justify-content: space-between;
    line-height: rem-calc(22);
    letter-spacing: rem-calc(-0.41);
    padding: rem-calc(0 16);
    width: 100%;

    .svg-icon,
    svg {
      display: block;
      fill: $dusty-gray;
      height: rem-calc(12);
      width: rem-calc(12);
    }

    .clebex-item-range-cta {
      color: $dusty-gray;
      font-size: rem-calc(17);
      line-height: rem-calc(22);
      letter-spacing: rem-calc(-0.41);
      text-decoration: none;
      padding: 0;
      margin: 0;
    }
  }
  section.expanded {
    &.one {
      max-height: 40px;
    }
    &.three {
      max-height: 120px;
    }
    &.four {
      max-height: 160px;
    }
    &.five {
      max-height: 200px;
    }
    &.ten {
      max-height: 400px;
    }
    &.fifteen {
      max-height: 600px;
    }
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -ms-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
  }
  section.collapsed {
    max-height: 0px;
    overflow: hidden;
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -ms-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
  }
}

.clebex-item-section-label {
  color: rgba($tuna, 0.6);
  display: block;
  font-size: rem-calc(13);
  line-height: rem-calc(21);
  padding: rem-calc(14 16 3);
  text-transform: uppercase;

  &.bg {
    background-color: $athens-gray;
  }

  &.with-button {
    padding-right: rem-calc(56);
    position: relative;

    button {
      align-items: center;
      display: flex;
      height: rem-calc(40);
      justify-content: center;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: rem-calc(40);
    }

    .svg-icon,
    svg {
      fill: $dusty-gray;
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }

  + .clebex-item-section {
    margin-top: 0;
  }
}
