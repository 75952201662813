.one-click-screen {
  .clebex-widget-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: rem-calc(27 16);
    justify-content: space-between;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    .clebex-widget-item-wrapper {
      width: 48%;
      height: 164px;
      margin: rem-calc(8 0);
      border-radius: 20px;
      &.clebex-widget-item-wrapper-ghost {
        border-radius: 20px;
        opacity: 0.5;
      }
      &.clebex-widget-item-wrapper-chosen {
        border-radius: 20px;
        outline: 1px solid $bright-gray;
      }
      &.clebex-widget-item-wrapper-drag {
        border-radius: 20px;
        outline: none;
      }
      &:first-child.plan {
        margin: rem-calc(0 0 8 0);
      }
      &.plan {
        width: 100%;
        height: 180px;
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
        .clebex-widget-item {
          text-align: left;
          padding: 10px;
          height: auto;
          min-height: 100%;
          &.empty-widget {
            div {
              margin-top: auto;
            }
          }
        }
      }
      &.space-passes {
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        scroll-snap-type: mandatory;
        scroll-snap-points-y: repeat(164px);
        scroll-snap-type: y mandatory;
        &::-webkit-scrollbar {
          display: none;
        }
        .clebex-widget-item {
          text-align: left;
          height: 100%;
        }
      }
      &.persons,
      &.info {
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        scroll-snap-type: mandatory;
        scroll-snap-points-y: repeat(164px);
        scroll-snap-type: y mandatory;
        background-color: $mischa;
        &::-webkit-scrollbar {
          display: none;
        }
        .clebex-widget-item {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 10px 15px 15px 15px;
        }
      }
      .clebex-widget-item {
        border-radius: 20px;
        background-color: $mischa;
        height: 100%;
        text-align: center; //remove after other widgets are done
        &.empty-widget {
          cursor: pointer;
          padding: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          svg {
            --color-2: $bright-gray;
          }
          .empty-widget-label {
            text-align: center;
            font-size: 18px;
            line-height: 22px;
            margin-top: auto;
          }
        }
        .clebex-widget-plan-wrapper {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px;
          border-radius: 14px;
          background-color: $white;
          box-shadow: 0px 3px 6px #00000029;
          &:not(:first-child) {
            margin-top: 5px;
          }
          .clebex-widget-plan-text {
            color: $bright-gray;
            width: 90%;
            min-height: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            line-height: 16px;
            .clebex-widget-plan-top {
              font-size: 14px;
              .clebex-widget-plan-top-parents {
                color: $dusty-gray;
              }
            }
            .clebex-widget-plan-bottom {
              color: $shuttleGray;
              font-size: 12px;
            }
            .label {
              color: $shuttleGray;
              font-size: rem-calc(14);
              .text {
                color: $dusty-gray;
                .highlight {
                  color: $bright-gray;
                }
              }
              .third-row-declaration {
                font-size: rem-calc(12);
              }
            }
          }
          .clebex-widget-plan-icon {
            width: 9px;
            height: 34px;
            border-radius: 5px;
            background-color: $success-color;
            margin-left: 20px;
            &.low {
              background-color: $success-color;
            }
            &.moderate {
              background-color: $warning-color;
            }
            &.high {
              background-color: $error-color;
            }
            &.not-resource {
              background-color: $lightGrey;
            }
          }
        }
        .clebex-widget-pass-wrapper {
          scroll-snap-align: start;
          scroll-snap-stop: always;
          height: 100%;
          cursor: pointer;
          padding: 10px;
          border-radius: 20px;
          background-color: $white;
          box-shadow: 0px 3px 6px #00000029;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .clebex-widget-pass-top-row {
            border-radius: 20px;
            font-weight: $font-weight-medium;
            .clebex-widget-pass-resource {
              font-size: 15px;
              color: $secondary-color;
              line-height: 20px;
            }
            .clebex-widget-pass-date {
              text-transform: uppercase;
              font-size: 13px;
              color: $dusty-gray;
              line-height: 21px;
            }
            .clebex-widget-pass-timerange {
              font-size: 13px;
              color: $primary-color;
              line-height: 21px;
            }
          }
          .clebex-widget-pass-bottom-row {
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .clebex-widget-pass-status {
              box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
              align-items: center;
              background-color: $dusty-gray;
              border-radius: 50%;
              display: flex;
              height: rem-calc(70);
              justify-content: center;
              padding: 0;
              width: rem-calc(70);

              &.active {
                background-color: $success-color;
              }

              &.warning {
                background-color: $warning-color;
              }

              &.error {
                background-color: $error-color;
              }

              .svg-icon,
              svg {
                fill: $white;
                height: rem-calc(40);
                width: rem-calc(40);
                --color-6: $white;
              }
            }
          }
        }
        .clebex-widget-contact-wrapper,
        .clebex-widget-info-wrapper {
          scroll-snap-align: start;
          scroll-snap-stop: always;
          scroll-margin: 10px 0 10px 0;
          cursor: pointer;
          height: 46%;
          width: 46%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 14px;
          background-color: $primary-color;
          box-shadow: 0px 3px 6px #00000029;
          color: $white;
          font-size: 25px;
          line-height: 16px;
          position: relative;
          margin-bottom: 15px;
          img {
            max-width: 122%;
          }
          img[alt]:after {
            display: block;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $primary-color;
            font-family: "Roboto";
            font-weight: 400;
            text-align: center;
            border-radius: 14px;
            content: attr(alt);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .clebex-widget-contact-content,
          .clebex-widget-info-content {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 14px;
          }
          .clebex-widget-contact-badge-wrapper {
            box-shadow: 0px 3px 6px #00000029;
            position: absolute;
            top: -5px;
            left: -5px;
            border-radius: 50%;
            overflow: hidden;
            .clebex-widget-contact-badge {
              padding: 5px;
              background-color: $primary-color;
              width: 25px;
              height: 25px;
              --color-1: #ffffff;
              --color-2: #ffffff;
            }
          }
        }
        .clebex-widget-info-wrapper {
          font-size: rem-calc(15);
          img[alt]:after {
            line-height: 4;
          }
        }
      }
    }
  }
  .one-click-footer-menu {
    background: rgba($alabaster, 0.94);
    box-shadow: rem-calc(0 -0.5 0) rgba($black, 0.3);

    .menu-items {
      align-items: flex-start;
      display: flex;
      list-style: none;
      margin: 0;
      justify-content: space-around;
      padding: rem-calc(5 0 0 0);
    }

    .menu-item {
      font-size: rem-calc(10);
      line-height: rem-calc(12);
      width: rem-calc(50);

      &:only-child:not(.central-item) {
        margin-right: auto;
      }

      [href],
      button {
        color: $dusty-gray;
        display: block;
        font-size: rem-calc(10);
        padding: 0;
        margin: 0;
        text-align: center;
        text-decoration: none;
        width: 100%;

        .svg-icon {
          display: block;

          svg {
            height: rem-calc(25);
            width: rem-calc(25);
          }
        }

        &.active {
          color: $secondary-color;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }

      &.central-item {
        height: rem-calc(60);
        transform: translateY(rem-calc(-20));
        width: rem-calc(60);

        [href],
        button {
          align-items: center;
          background: linear-gradient(
            180deg,
            $eastern-blue 0%,
            $primary-color 100%
          );
          border-radius: 50%;
          color: rgba($white, 0.9);
          display: flex;
          font-size: rem-calc(15);
          height: rem-calc(60);
          letter-spacing: rem-calc(-0.83);
          line-height: rem-calc(20);
          justify-content: center;
          text-transform: uppercase;
          text-shadow: rem-calc(0 3 6) rgba($black, 0.16);
          width: rem-calc(60);

          .svg-icon {
            height: rem-calc(38);

            svg {
              height: rem-calc(38);
              width: rem-calc(38);
            }
          }

          &.disabled,
          &:disabled {
            background: $dusty-gray;
            cursor: not-allowed;
          }

          &.cancel {
            background: $white;
            padding: 0;

            .svg-icon {
              height: rem-calc(60);

              svg {
                height: rem-calc(60);
                width: rem-calc(60);
              }
            }
          }
        }
      }
    }
  }
  .label.find-person-list {
    color: $bright-gray !important;
    .clebex-widget-plan-top {
      font-size: 14px;
      span {
        color: $dusty-gray;
      }
    }
    .clebex-widget-plan-bottom {
      color: $shuttleGray;
      font-size: 12px;
    }
  }
}
