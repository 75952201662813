.search-filter-screen {
  .capacity-filter-input-section {
    border-top: 0;
    align-items: center;
    display: flex;
    color: $dusty-gray;
    font-size: rem-calc(17);
    line-height: rem-calc(22);
    letter-spacing: rem-calc(-0.41);
    text-decoration: none;
    padding: rem-calc(2 0);
    margin: 0;

    .capacity-filter-btn {
      appearance: none;
      color: $dusty-gray;
      font-size: rem-calc(17);
      line-height: rem-calc(22);
      letter-spacing: rem-calc(-0.41);
      flex-shrink: 0;
      text-decoration: none;
      margin: 0;
      padding: 0;
      text-align: center;
      width: rem-calc(40);
    }

    .capacity-filter-input-wrapper {
      flex: 1;
      text-align: center;
      width: 100%;
      input {
        text-align: center;
      }
    }
  }
  .clebex-item-content-wrapper {

    .selected-total {
      color: $bright-gray !important;
    }

    .search-preset {
      .follow-up-icon-item {
        width: rem-calc(30);
        .svg-icon {
          width: rem-calc(30);
        }
      }
      &:hover {
        svg {
          --color-2: #f45417;
        }
      }
    }

    .edit-preset {
      &:hover {
        svg {
          --color-1: #737373;
        }
      }
      svg {
        --color-1: #a3a3a3;
      }
    }

    .delete-preset:hover {
      svg {
        --color-2: #f45417;
      }
    }

    .checkbox.slide {
      margin-right: rem-calc(16);
    }
  }
}

.service-options {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: none;

  @container (#{map-get($grid-breakpoints, two)}) {
    flex-direction: row !important;
    border-bottom: 1px solid $paynesGreyTransparent;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1) brightness(50%) sepia(100%) saturate(10000%)
      hue-rotate(90deg);
  }

  .service-selected-date {
    font-size: rem-calc(25);
    font-weight: 600;
    color: $primary-color;
    text-align: center;
    background-color: $primary-color-tint;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem-calc(56);

    @container (#{map-get($grid-breakpoints, two)}) {
      width: rem-calc(300);
      font-size: rem-calc(21);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .service-calendar-wrapper {
    position: absolute;
    z-index: 1;
    max-width: rem-calc(375);
  }

  .service-calendar {
    width: 100%;
    font-size: 21px;
    font-weight: 600;
    color: $primary-color;
    text-align: center;
    background-color: $primary-color-tint;
    box-shadow: none;

    @container (#{map-get($grid-breakpoints, two)}) {
      width: rem-calc(200);
    }

    &:focus-visible {
      outline: none;
    }
  }

  .service-summary {
    display: flex;
    align-items: center;
    background-color: $primary-color-tint;
    padding: 0 10px;
    justify-content: space-between;
    min-height: rem-calc(46);
    min-width: rem-calc(325);
    border-top: 1px solid $paynesGreyTransparent;

    @container (#{map-get($grid-breakpoints, two)}) {
      border-top: none;
      padding: 0 40px 0 10px;
    }

    .service-summary-item {
      position: relative;

      &:hover {
        cursor: pointer;
      }
    }

    .service-count {
      position: absolute;
      top: 16px;
      right: 4px;
      height: rem-calc(18);
      min-width: rem-calc(18);
      background: $primary-color;
      color: $white;
      text-align: center;
      font-size: rem-calc(13);
      line-height: 18px;
      border-radius: 50%;

      &.active {
        background: $secondary-color;
      }
    }
  }

  .search-wrapper {
    width: 100%;
  }
}