.actions-menu {
  .actions-list {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .action-btn {
    color: $secondary-color;
    font-size: rem-calc(14);
    height: rem-calc(44);
    letter-spacing: rem-calc(-0.41);
    line-height: rem-calc(22);
    padding: rem-calc(0 16);

    &:disabled {
      cursor: not-allowed;
      color: $dusty-gray;
    }

    .svg-icon,
    svg {
      fill: $dusty-gray;
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }

  &.theme-gray {
    .action-btn {
      color: $dusty-gray;

      &.active {
        color: $secondary-color;
      }
    }
  }
  &.sync-footer {
    .action-btn {
      svg {
        --color-2: #737373;
      }
    }
  }
}
