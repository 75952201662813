.advanced-search-wrapper {
  display: flex;

  .search-field {
    [type="text"] {
      padding: rem-calc(0 36 0 30);
    }
  }
}

.search-field {
  flex: 1;
  position: relative;

  .icon {
    display: block;
    left: rem-calc(6);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .svg-icon {
    height: rem-calc(16);
    width: rem-calc(16);

    svg {
      fill: rgba($tuna, 0.6);
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }

  [type="text"] {
    background-color: $white;
    border-radius: rem-calc(10);
    box-shadow: none;
    color: rgba($tuna, 0.6);
    font-size: rem-calc(17);
    height: rem-calc(36);
    letter-spacing: rem-calc(-0.56);
    padding: rem-calc(0 10 0 30);
    width: 100%;

    &::placeholder {
      color: rgba($tuna, 0.6);
    }

    &:focus-visible {
      outline: none;
    }
  }

  .clear-field {
    align-items: center;
    display: flex;
    height: rem-calc(36);
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: rem-calc(36);

    .svg-icon,
    svg {
      fill: rgba($tuna, 0.6);
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }
}

.open-filters-button {
  background-color: $white;
  border-radius: rem-calc(10);
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: rem-calc(12);
  padding: 0;
  position: relative;
  height: rem-calc(36);
  width: rem-calc(36);

  .svg-icon,
  svg {
    height: rem-calc(20);
    width: rem-calc(20);
    --color-2: #a3a3a3;
  }

  .indicator {
    align-items: center;
    background-color: $secondary-color;
    border-radius: rem-calc(8);
    color: $white;
    display: flex;
    font-size: rem-calc(12);
    height: rem-calc(16);
    justify-content: center;
    min-width: rem-calc(16);
    padding: rem-calc(0 3);
    position: absolute;
    top: 0;
    right: 0;
    transform: translate3d(50%, -50%, 0);
  }
}

.search-filter-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;

  .search-filter-item {
    align-items: center;
    background-color: $white;
    border-radius: rem-calc(10);
    display: flex;
    color: rgba($tuna, 0.6);
    font-size: rem-calc(12);
    padding: rem-calc(0 5);
    margin: rem-calc(10 10 0 0);
    white-space: nowrap;
    height: rem-calc(30);

    .name {
      font-size: rem-calc(12);
    }
  }

  .search-filter-remove-btn {
    align-items: center;
    display: flex;
    justify-content: right;
    padding: 0;
    width: rem-calc(25);

    .svg-icon,
    svg {
      fill: rgba($tuna, 0.6);
      height: rem-calc(16);
      width: rem-calc(16);
    }
  }
}
