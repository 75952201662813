.preference-list,
.company-preference-list {
  .clebex-item-section-item {
    cursor: pointer;

    .clebex-section-input-label {
      cursor: pointer;
    }

    &.favorite-level {
      cursor: default;
    }

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
    }

    #meetingTItle {
      border: none;
    }
  }

  .clebex-item-section {
    &.date-time-format,
    &.company-preference-calendar {
      .select-time-btn {
        width: rem-calc(85);
      }

      .select-time-picker {
        height: auto;
        justify-content: center;
        .list-of-time-slots {
          width: 100%;
          padding: rem-calc(0 20);
        }
        .select-time-button-wrapper {
          &.double {
            width: 50%;
          }

          &.triple {
            width: 33%;
          }

          &.quad {
            width: 25%;
          }
        }
      }
    }

    &.company-preference-calendar {
      .select-time-picker {
        .select-time-button-wrapper {
          width: 15%;
          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .date-time-preference {
    padding: rem-calc(0 16) !important;
    .date-time-section {
      display: flex;
      width: 50%;
    }
  }
}

.company-preference-list {
  .choose-color {
    height: 20px;
    width: 40px;
    border: 1px solid $dusty-gray;
    border-radius: 5px;
    margin-right: 24px;
  }
  .hidden {
    display: none;
  }
  .hidden-underline {
    &::after {
      border-bottom: none;
    }
  }
}

.company-preferences-input-section {
  border-top: 0;
  align-items: center;
  display: flex;
  color: $dusty-gray;
  font-size: rem-calc(17);
  line-height: rem-calc(22);
  letter-spacing: rem-calc(-0.41);
  text-decoration: none;
  padding: rem-calc(2 0);
  margin: 0;

  .company-preferences-btn {
    appearance: none;
    color: $dusty-gray;
    font-size: rem-calc(17);
    line-height: rem-calc(22);
    letter-spacing: rem-calc(-0.41);
    flex-shrink: 0;
    text-decoration: none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: rem-calc(40);
  }

  .company-preferences-input-wrapper {
    flex: 1;

    [type="text"] {
      text-align: center;
      width: 100%;
    }
  }
}
