.page-not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  h1 {
    font-size: rem-calc(28);
    font-weight: $font-weight-semi-bold;
  }
}