.clebex-pill-menu-tabs {
  width: 100%;

  .clebex-pill-menu-nav {
    padding: rem-calc(6 16);
  }
}

.clebex-pill-tabs-content {
  display: none;

  &.visible {
    display: block;
  }
  .hours-placeholder {
    top: rem-calc(65) !important;
  }
}
