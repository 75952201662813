%common-props {
  align-items: center;
  display: flex;
  justify-content: center;
}

.top-header-menu {
  align-items: center;
  display: flex;
  height: rem-calc(70);
  justify-content: space-between;
  padding-top: rem-calc(26);

  @media #{$touch-device-query} {
    height: rem-calc(44);
    padding-top: 0;
  }

  .left-action,
  .right-action {
    @extend %common-props;
    flex-shrink: 0;
    width: rem-calc(88);

    button,
    a,
    > div {
      @include ellipsis;
      color: inherit;
      align-items: center;
      background: transparent;
      box-shadow: none;
      border: none;
      display: flex;
      font-size: rem-calc(17);
      height: rem-calc(44);
      line-height: rem-calc(17);
      min-width: rem-calc(44);
      justify-content: center;
      padding: rem-calc(0 8);
      text-decoration: none;

      > span {
        @include ellipsis;
      }
    }

    .text {
      display: block;
      padding: 0;
    }
  }

  .left-action {
    justify-content: flex-start;
    .disabled-button {
      cursor: not-allowed !important;
      svg {
        opacity: 0.4;
        --color-1: white;
      }
    }
  }

  .right-action {
    justify-content: flex-end;
  }

  .center-info {
    @extend %common-props;
    overflow: hidden;
    padding: rem-calc(0 10);
    text-align: center;
    flex-grow: 1;
  }

  &.pale-green {
    background-color: rgba($primary-color, 0.05);
  }

  .component-title {
    @include ellipsis;
    font-size: rem-calc(17);
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;
    width: 100%;
  }

  .open-main-menu-button {
    height: rem-calc(44);
    width: rem-calc(44);

    @media (#{map-get($grid-breakpoints, one)}) {
      visibility: hidden;
    }
  }

  .svg-icon {
    fill: $secondary-color;

    svg {
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }
  .user-info {
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    width: 100%;
  }

  .user-name {
    @include ellipsis;
    font-size: rem-calc(16);
    line-height: rem-calc(21);
    font-weight: $font-weight-bold;
  }

  .user-type {
    @include ellipsis;
    font-size: rem-calc(14);
    line-height: rem-calc(19);
  }
  &.main-nav-header {
    .left-action,
    .right-action {
      width: auto;
      max-width: rem-calc(88);
    }
  }
}
