.today-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 30px;
  color: #24b7af;
  font-size: 18px;
  font-weight: bold;
  border-right: 0px;
  border-bottom: 1px solid lightgray;
  background-color: #e9f8f7;
  cursor: pointer;

  @container (#{map-get($grid-breakpoints, two)}) {
    display: block;
    border-right: 1px solid lightgray;
    border-bottom: 0px;
  }
}

.status-dialog {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: #00000066;
  z-index: 3;
  width: 100%;
  justify-content: center;
  align-items: center;

  .dialog-wrapper {
    display: flex;
    text-align: center;
    flex-direction: column;
    background: $lightWhisper;
    border-radius: rem-calc(12);
    box-shadow: rem-calc(2) rem-calc(7) rem-calc(11) rgba(80, 92, 51, 0.17);
    width: rem-calc(260);
    min-height: rem-calc(160);
    height: auto;

    .dialog-container {
      display: flex;
      flex: 1;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      margin: rem-calc(8);

      .dialog-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding-top: rem-calc(24);
        padding-bottom: rem-calc(24);
        color: $jumbo;
        line-height: rem-calc(24);

        .dialog-body-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          &:not(:last-child) {
            padding-bottom: rem-calc(8);
          }

          &.half-width {
            width: 50%;
          }

          input[type="text"] {
            height: rem-calc(24);
            padding: 0 rem-calc(8);
            margin-left: rem-calc(8);
            border-radius: rem-calc(4);
            border: 0;
            box-shadow: none;
            width: 100%;
          }
        }
      }

      .dialog-close {
        display: flex;
        justify-content: space-around;
        width: 100%;
        border-top: rem-calc(1) solid $gray;

        .dialog-action {
          color: $primary-color;
          font-weight: 600;
          padding-top: rem-calc(12);
          padding-bottom: rem-calc(8);
          text-transform: uppercase;
          width: 50%;

          &:hover {
            cursor: pointer;
          }

          &:first-child {
            border-right: rem-calc(1) solid $gray;
          }
        }
      }
    }
  }
}