.favorite-list {
  list-style: none;
  margin: 0;
  padding: 0;

  .favorite-item {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .favorite-left-action,
  .favorite-right-action {
    width: rem-calc(40);

    .svg-icon {
      align-items: center;
      display: flex;
      height: rem-calc(40);
      justify-content: center;
      width: rem-calc(40);
    }

    svg {
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }

  .favorite-left-action svg {
    fill: $warning-color;
  }

  .favorite-right-action svg {
    fill: $dusty-gray;
  }

  .favorite-left-info {
    padding: rem-calc(4 0);
    font-size: rem-calc(12);
  }

  .favorite-right-info {
    margin-left: auto;

    button {
      font-size: rem-calc(16);
    }
  }

  .parent-name {
    display: block;
    line-height: 1.5;
  }
}