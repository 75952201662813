.select-slots-buttons {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: rem-calc(10 0 13);

  .title {
    color: $bright-gray;
    font-size: rem-calc(15);
    font-weight: $font-weight-semi-bold;
    letter-spacing: rem-calc(-0.83);
    line-height: rem-calc(20);
    margin-right: rem-calc(25);
  }

  .slot-group-selector {
    margin-right: rem-calc(30);

    &:last-child {
      margin-right: 0;
    }
  }

  .select-slot-group {
    padding: 0;

    .svg-icon,
    svg {
      height: rem-calc(32);
      width: rem-calc(32);
    }
  }
}

.slot-lists {
  padding-top: rem-calc(5);
  .list-wrapper {
    display: none;
    padding: rem-calc(0 5);
    width: 100%;

    &.selected {
      display: block;
    }
  }

  .list-wrapper-quatro {
    display: none;

    &.selected {
      display: block;

      .list-wrapper {
        display: block;
      }
    }
  }

  .selected-date {
    align-items: center;
    color: $dusty-gray;
    display: flex;
    font-size: rem-calc(15);
    font-weight: $font-weight-semi-bold;
    letter-spacing: rem-calc(-0.36);
    margin-bottom: rem-calc(10);
    padding-left: rem-calc(20);

    .svg-icon {
      height: rem-calc(20);
      margin-right: rem-calc(5);
      transform: translateY(rem-calc(-1));
      width: rem-calc(20);
    }

    svg {
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }

  .slot-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  .slot {
    padding: rem-calc(0 5);
    margin-bottom: rem-calc(10);
    max-width: 50%;
    width: 50%;
  }

  .slot-select-button {
    border: 1px solid $mystic;
    border-radius: rem-calc(10);
    display: block;
    padding: rem-calc(5);
    width: 100%;

    &:disabled {
      cursor: not-allowed;
      opacity: .5;
    }

    .duration {
      color: $dusty-gray;
      display: block;
      font-size: rem-calc(14);
      font-weight: $font-weight-semi-bold;
      letter-spacing: rem-calc(-0.78);
      line-height: rem-calc(19);
      text-align: center;
      text-transform: uppercase;

      span {
        color: rgba($dusty-gray, .7);
      }
    }

    .meta {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .date {
      color: $dusty-gray;
      font-size: rem-calc(13);
      letter-spacing: rem-calc(-0.72);
      line-height: rem-calc(18);
    }

    .occupancy {
      color: rgba($dusty-gray, .7);
      display: block;
      font-size: rem-calc(15);
      font-weight: $font-weight-semi-bold;
      letter-spacing: rem-calc(-0.83);
      line-height: rem-calc(20);
      margin-left: rem-calc(34);

      &.low {
        color: $success-color;
      }

      &.moderate {
        color: $warning-color;
      }

      &.high {
        color: $error-color;
      }
    }

    &.occupied {
      background-color: rgba($primary-color, 0.05);
    }

    &.selected {
      background-color: $primary-color;

      .occupancy,
      .duration,
      .duration span,
      .date {
        color: $white !important;
      }
    }
  }

  .quatro {
    .slot {
      max-width: 25%;
      width: 25%;
    }

    .slot-select-button {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .occupancy {
      font-size: rem-calc(14);
      letter-spacing: rem-calc(-0.78);
      line-height: rem-calc(19);
      margin: 0;
    }

    .hour {
      display: block;
      font-size: rem-calc(13);
      line-height: rem-calc(18);
      letter-spacing: rem-calc(-0.72);
      white-space: nowrap;
    }
  }
}
