html,
body {
  height: 100%;
  overscroll-behavior-y: none;
  overflow: hidden;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

img,
svg {
  display: inline-block;
  max-width: 100%;
}

#app {
  height: 100%;
  overflow-x: hidden;
  --popper-theme-padding: 8px;
  --popper-theme-border-radius: 6px;
  --popper-theme-background-color: #24b7af;
  --popper-theme-text-color: white;
  .popper-warning {
    --popper-theme-background-color: #ff3131;
  }
}

.items-list {
  background: $white;

  .list-item {
    align-items: center;
    border-bottom: 1px solid rgba($tuna, 0.29);
    color: $bright-gray;
    display: flex;
    height: rem-calc(44);
    padding: rem-calc(0 16);
    font-size: rem-calc(17);
    font-weight: rem-calc(22);
    letter-spacing: rem-calc(-0.41);
    width: 100%;
  }
}

.app > .screen-loader-wrapper {
  position: fixed;
}

.global-hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.w-100 {
  width: 100% !important;
}

.not-allowed {
  cursor: not-allowed !important;
}

.disabled {
  color: $dusty-gray !important;
  cursor: not-allowed !important;
  pointer-events: none;
}

.overflow-hidden {
  overflow: hidden !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.rotate-1 {
  transform: rotate(180deg) !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.p-0 {
  padding: 0 !important;
}
.pr-1 {
  padding-right: rem-calc(4) !important;
}
.pl-1 {
  padding-left: rem-calc(4) !important;
}
.pr-2 {
  padding-right: rem-calc(8) !important;
}
.pl-2 {
  padding-left: rem-calc(8) !important;
}
.mr-1 {
  margin-right: rem-calc(4) !important;
}
.ml-1 {
  margin-left: rem-calc(4) !important;
}
.m-0 {
  margin: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}

.no-pointer-events {
  pointer-events: none !important;
  cursor: not-allowed !important;
}
.pagination-element-list {
  position: relative;
  .parents {
    color: $french-gray !important;
  }
  .clebex-pagination-count-wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 40px;
    min-width: 100px;
    height: 30px;
    z-index: 1;
    text-align: center;
    padding: 5px 20px;
    border: 2px solid $athens-gray;
    border-radius: 7px;
    color: $dusty-gray;
    background-color: $white;
    font-size: 12px;
    font-weight: $font-weight-semi-bold;
    line-height: 16px;
  }
}
