.add-company-document-page {
    flex-grow: 1;

    .clebex-item-section-item,
    .clebex-item-section {
      &.mail-flex {
        border: none;
        display: flex;
        padding: rem-calc(10);
        flex: 1;
        margin-top: 0;

        .clebex-item-content-wrapper {
          align-items: normal;
          .clebex-section-input {
            .document-content {
              text-align: justify;
              padding: 1rem;
              height: rem-calc(150);
              overflow-y: auto;
              div {
                white-space: pre-wrap;
              }
            }
          }
        }

      }

      .clebex-item-content-wrapper {
        &.has-link {
          cursor: pointer;
          label {
            cursor: pointer;
          }

          a {
            text-decoration: none;
          }
        }

        .document-calendar-wrapper {
          top: rem-calc(49);
          position: absolute;
          width: rem-calc(375);
          margin-left: rem-calc(16);
          border: rem-calc(1) solid $paynesGreyTransparent;
          border-radius: rem-calc(4);
          z-index: 1;
        }
      }
    }

    .clebex-section-input {
      &.preview {
        padding: rem-calc(16);
        background-color: $athens-gray;
      }

      input[type="text"],
      textarea {
        &:focus-visible {
          outline: none;
        }
      }

      textarea {
        resize: none;
        display: flex;
        flex: 1;
      }
    }

    .company-document-footer-menu {
      background: rgba($alabaster, 0.94);
      box-shadow: rem-calc(0 -0.5 0) rgba($black, 0.3);
  
      .menu-items {
        align-items: flex-start;
        display: flex;
        list-style: none;
        margin: 0;
        justify-content: space-around;
        padding: rem-calc(5 0 0 0);
      }
  
      .menu-item {
        font-size: rem-calc(10);
        line-height: rem-calc(12);
        width: rem-calc(50);
  
        &:only-child {
          margin-right: auto;
        }
  
        [href],
        button {
          color: $dusty-gray;
          display: block;
          font-size: rem-calc(10);
          padding: 0;
          margin: 0;
          text-align: center;
          text-decoration: none;
          width: 100%;
  
          .svg-icon {
            display: block;
  
            svg {
              height: rem-calc(25);
              width: rem-calc(25);
            }
          }
  
          &.active {
            color: $secondary-color;
          }
  
          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
  
        &.central-item {
          height: rem-calc(60);
          transform: translateY(rem-calc(-20));
          width: rem-calc(60);
          margin: 0 auto;
  
          [href],
          button {
            align-items: center;
            background: linear-gradient(
              180deg,
              $eastern-blue 0%,
              $primary-color 100%
            );
            border-radius: 50%;
            color: rgba($white, 0.9);
            display: flex;
            font-size: rem-calc(15);
            height: rem-calc(60);
            letter-spacing: rem-calc(-0.83);
            line-height: rem-calc(20);
            justify-content: center;
            text-transform: uppercase;
            text-shadow: rem-calc(0 3 6) rgba($black, 0.16);
            width: rem-calc(60);
  
            .svg-icon {
              height: rem-calc(38);
  
              svg {
                height: rem-calc(38);
                width: rem-calc(38);
              }
            }
  
            &.disabled,
            &:disabled {
              background: $dusty-gray;
              cursor: not-allowed;
            }
  
            &.cancel {
              background: $white;
              padding: 0;
  
              .svg-icon {
                height: rem-calc(60);
  
                svg {
                  height: rem-calc(60);
                  width: rem-calc(60);
                }
              }
            }
          }
        }
      }
    }
}

