%base-input {
  appearance: none;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  display: block;
  font-family: $primary-font;
  font-size: rem-calc(16);
  font-weight: $font-weight-light;
  line-height: rem-calc(16);
  // transform: translateZ(0); // fix za text koji se ne vidi na IOS napravama

  &:disabled {
    cursor: not-allowed;
    opacity: 1;
    -webkit-text-fill-color: $dusty-gray;
  }
}

[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"],
[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
select {
  @extend %base-input;
  box-shadow: rem-calc(0 2 4) rgba($black, 0.1);
  height: rem-calc(56);
  padding: rem-calc(0 20);
}

textarea {
  @extend %base-input;
  height: rem-calc(100);
  padding: rem-calc(10 20);
  resize: vertical;
  width: 100%;
}

.tiny-input {
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="url"],
  [type="color"],
  [type="text"],
  [type="password"],
  [type="date"],
  [type="datetime"],
  [type="datetime-local"],
  [type="month"],
  [type="week"],
  [type="email"],
  [type="number"],
  select {
    @extend %base-input;
    background-color: $white;
    border-bottom: 1px solid rgba($tuna, 0.29);
    border-top: 1px solid rgba($tuna, 0.29);
    box-shadow: none;
    color: $mako;
    font-size: rem-calc(17);
    font-weight: $font-weight-regular;
    height: rem-calc(44);
    line-height: rem-calc(36);
    padding: rem-calc(0 16);
    width: 100%;
  }
  &.no-borders {
    [type="search"],
    [type="tel"],
    [type="time"],
    [type="url"],
    [type="color"],
    [type="text"],
    [type="password"],
    [type="date"],
    [type="datetime"],
    [type="datetime-local"],
    [type="month"],
    [type="week"],
    [type="email"],
    [type="number"],
    select {
      border: none;
    }
    .field-wrapper-with-button {
      button {
        border: none;
      }
    }
  }
}

.inline-input {
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="url"],
  [type="color"],
  [type="text"],
  [type="password"],
  [type="date"],
  [type="datetime"],
  [type="datetime-local"],
  [type="month"],
  [type="week"],
  [type="email"],
  [type="number"],
  select {
    @extend %base-input;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: $bright-gray;
    font-size: rem-calc(17);
    font-weight: $font-weight-regular;
    height: rem-calc(40);
    line-height: rem-calc(40);
    padding: 0;
    width: 100%;
  }
}

button {
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  font-family: inherit;
  -webkit-appearance: button;
}

.btn,
.button,
[type="submit"] {
  appearance: none;
  border: 0;
  border-radius: 0;
  box-shadow: rem-calc(0 4 8) rgba($black, 0.2);
  cursor: pointer;
  font-family: $primary-font;
  font-size: rem-calc(14);
  font-weight: $font-weight-semi-bold;
  height: rem-calc(56);
  line-height: rem-calc(19);
  padding: rem-calc(14 16);
  text-decoration: none;
  text-transform: uppercase;

  &:visited {
    // color: $dark-purple
  }

  &:active {
    background-color: rgba($black, 0.1);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media (hover: hover) {
    &:hover {
      // color: $white;
    }
  }

  &.primary {
    background-color: $primary-color;
    color: $white;
  }

  &.light {
    background-color: $white;
    color: $primary-color;
  }

  &.tiny {
    font-size: rem-calc(12);
    font-weight: $font-weight-light;
    line-height: rem-calc(16);
  }

  &.text {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }

  &.rounded {
    box-shadow: rem-calc(0 5 10) rgba($black, 0.2);
    border-radius: rem-calc(10);
    height: rem-calc(55);
  }
}

.inner-layout-btn {
  color: $secondary-color;
  font-size: rem-calc(16);
  padding: 0;
  text-transform: uppercase;

  + .inner-layout-btn {
    margin-left: rem-calc(16);
  }

  &:disabled {
    cursor: not-allowed;
    color: $dusty-gray;
  }

  .svg-icon,
  svg {
    fill: $dusty-gray;
    height: rem-calc(20);
    width: rem-calc(20);
  }

  &.delete {
    color: $error-color;
  }
}

.body-btn {
  align-items: center;
  background-color: $polar;
  border-radius: rem-calc(10);
  box-shadow: rem-calc(0 3 6) rgba($black, 0.2);
  color: $primary-color;
  display: flex;
  font-size: rem-calc(18);
  height: rem-calc(44);
  justify-content: center;
  letter-spacing: rem-calc(-0.43);
  line-height: rem-calc(12);
  text-decoration: none;
  width: 100%;

  &:disabled {
    color: $dusty-gray;
    cursor: not-allowed;
  }
}

.block-list-item-button {
  align-items: center;
  color: $bright-gray;
  display: flex;
  font-size: rem-calc(17);
  line-height: rem-calc(22);
  padding: rem-calc(0 0 0 40);
  position: relative;
  text-decoration: none;
  width: 100%;

  &::after {
    border-bottom: 1px solid rgba($tuna, 0.29);
    bottom: 0;
    content: "";
    display: block;
    left: rem-calc(40);
    pointer-events: all;
    position: absolute;
    right: 0;
  }

  > span {
    display: block;
    padding: rem-calc(9 0);
  }

  .timezone-icon {
    display: inline-block;
    vertical-align: middle;
    padding-left: rem-calc(4);
    position: relative;

    .svg-icon {
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }

  .address-icon {
    display: inline-block;
    vertical-align: middle;
    padding-left: rem-calc(4);
    position: relative;

    .svg-icon {
      height: rem-calc(20);
      width: rem-calc(20);
    }

    svg {
      fill: $eastern-blue;
    }
  }

  > .svg-icon {
    align-items: center;
    display: flex;
    height: rem-calc(40);
    justify-content: center;
    width: rem-calc(40);

    &:first-child {
      left: 0;
      position: absolute;
      top: 0;
    }

    &:last-child:not(:first-child) {
      margin-left: auto;
      flex-shrink: 0;
    }

    &.wrapper .svg-icon {
      position: static;
    }
  }

  svg {
    fill: $dusty-gray;
    height: rem-calc(20);
  }

  .favorite {
    svg {
      fill: $warning-color;
    }
  }

  &.no-left-icon {
    padding-left: rem-calc(16);

    &::after {
      left: 0;
    }
  }
}

.error-message {
  color: rgba($error-color, 0.8);
  font-size: rem-calc(13);
  line-height: rem-calc(18);
}

.checkbox {
  color: $bright-gray;
  [type="checkbox"],
  [type="radio"] {
    height: 0;
    position: absolute;
    visibility: hidden;
    width: 0;
    z-index: -1;
    &:disabled + label {
      cursor: not-allowed;

      .inner-text {
        opacity: 0.6;
      }
    }
  }

  label:active {
    background-color: rgba($black, 0.1);
  }

  &.slide {
    label {
      align-items: center;
      background-color: rgba($jumbo, 0.16);
      border-radius: rem-calc(15);
      cursor: pointer;
      display: flex;
      height: rem-calc(26);
      justify-content: flex-start;
      padding: rem-calc(0 2);
      position: relative;
      transition: all 0.3s ease-in-out;
      width: rem-calc(42);

      &::before {
        background-color: $white;
        border-radius: 50%;
        display: block;
        content: "";
        height: rem-calc(22);
        transition: all 0.3s ease-in-out;
        width: rem-calc(22);
      }
    }

    [type="checkbox"],
    [type="radio"] {
      &:checked + label {
        background-color: $primary-color;
        justify-content: flex-end;
      }
    }
  }

  &.tiny {
    label {
      align-items: center;
      color: $black;
      cursor: pointer;
      display: flex;
      font-size: rem-calc(17);
      line-height: rem-calc(22);
      letter-spacing: rem-calc(-0.41);
      padding-left: rem-calc(27);
      position: relative;
      width: 100%;

      &::before {
        border: 1px solid $dusty-gray;
        border-radius: 50%;
        content: "";
        height: rem-calc(14);
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: rem-calc(14);
      }

      > .svg-icon {
        display: none;
        fill: $white;
        height: rem-calc(7);
        left: rem-calc(2);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: rem-calc(7);

        svg {
          height: rem-calc(7);
          --color-6: $white;
          --color-7: $white;
          fill: $white;
          width: rem-calc(7);
        }
      }
    }

    .timezone-icon {
      display: inline-block;
      vertical-align: middle;
      padding-left: rem-calc(4);
      position: relative;

      .svg-icon {
        height: rem-calc(20);
        width: rem-calc(20);
      }

      svg {
        height: rem-calc(20);
        width: rem-calc(20);
      }
    }

    .address-icon {
      display: inline-block;
      padding-left: rem-calc(4);
      vertical-align: middle;
      position: relative;

      .svg-icon {
        height: rem-calc(20);
        width: rem-calc(20);
      }

      svg {
        height: rem-calc(20);
        width: rem-calc(20);
        fill: $eastern-blue;
      }
    }

    [type="checkbox"]:checked + label,
    [type="radio"]:checked + label {
      background-color: rgba($primary-color, 0.05);
      &::before {
        background-color: $dusty-gray;
      }

      > .svg-icon {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }

    &.no-background {
      [type="checkbox"]:checked + label,
      [type="radio"]:checked + label {
        background-color: transparent;
      }
    }

    &.alt {
      label {
        padding-left: rem-calc(40);

        &::before {
          left: rem-calc(13);
        }

        > .svg-icon {
          left: rem-calc(16);
        }
      }
    }
  }

  &.gray-label label {
    color: $dusty-gray;
  }

  &.block {
    display: block;
    width: 100%;

    label {
      min-height: rem-calc(45);
      width: 100%;
    }
  }

  &.multi {
    label {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: rem-calc(0 55 0 16);
      width: 100%;

      .svg-icon {
        align-items: center;
        display: none;
        height: rem-calc(45);
        position: absolute;
        right: 0;
        top: 0;
        width: rem-calc(45);
      }

      svg {
        height: rem-calc(18);
        fill: $primary-color;
        width: rem-calc(18);
      }
    }

    [type="checkbox"]:checked + label .svg-icon,
    [type="radio"]:checked + label .svg-icon {
      display: flex;
    }
  }
}

.tiny-label {
  color: rgba($tuna, 0.6);
  display: block;
  font-size: rem-calc(13);
  line-height: rem-calc(21);
  padding: rem-calc(14 16 3);
  text-transform: uppercase;

  &.bg {
    background-color: $athens-gray;
  }

  &.with-button {
    padding-right: rem-calc(56);
    position: relative;

    button {
      align-items: center;
      display: flex;
      height: rem-calc(40);
      justify-content: center;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: rem-calc(40);
    }

    .svg-icon,
    svg {
      fill: $dusty-gray;
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }
}

.field-wrapper-with-button {
  position: relative;

  &.multi {
    input:first-child {
      border-bottom: none;
    }
  }

  button {
    align-items: center;
    background-color: $white;
    bottom: 0;
    border-bottom: 1px solid rgba($tuna, 0.29);
    border-left: 1px solid rgba($tuna, 0.29);
    border-top: 1px solid rgba($tuna, 0.29);
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: rem-calc(44);

    .svg-icon,
    svg {
      fill: $bright-gray;
      height: rem-calc(20);
      width: rem-calc(20);
    }
  }
}
