.space-and-resource-selection {
  padding: rem-calc(0 16);

  .selection-header {
    padding: rem-calc(15 0);
  }

  .selection-options {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .selection-option {
    margin-bottom: rem-calc(15);

    &:first-child {
      margin-bottom: rem-calc(10);
    }
  }

  .selection-option-button {
    align-items: center;
    border: 1px solid $mystic;
    border-radius: rem-calc(10);
    color: $primary-color;
    display: flex;
    font-size: rem-calc(22);
    letter-spacing: rem-calc(0.53);
    line-height: 1;
    padding: rem-calc(6 7);
    text-transform: uppercase;
    width: 100%;

    .icon {
      margin-right: rem-calc(7);
      visibility: hidden;
    }

    .svg-icon {
      align-items: center;
      background-color: $white;
      border-radius: 50%;
      box-shadow: rem-calc(0 3 6) rgba($black, .16);
      display: flex;
      height: rem-calc(30);
      justify-content: center;
      width: rem-calc(30);

      svg {
        fill: $primary-color;
        height: rem-calc(20);
        width: rem-calc(20);
      }
    }

    &.selected {
      background-color: $primary-color;
      border-color: $primary-color;
      box-shadow: rem-calc(0 5 5) rgba($black, 0.2);
      color: $white;

      .icon {
        visibility: visible;
      }
    }
  }

  .input-group {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .search-field {
    [type=text] {
      background-color: rgba($jumbo, 0.12);
    }
  }

  .search-action {
    margin-left: rem-calc(16);
    width: rem-calc(44);

    .svg-icon {
      height: rem-calc(24);
      width: rem-calc(24);

      svg {
        fill: rgba($tuna, 0.6);
        height: rem-calc(24);
        width: rem-calc(24);
      }
    }
  }

  .search-action-button {
    align-items: center;
    background-color: rgba($jumbo, 0.12);
    border-radius: rem-calc(10);
    display: flex;
    height: rem-calc(36);
    justify-content: center;
    padding: 0;
    width: 100%;
  }

  .resource-list {
    border: 1px solid $mystic;
    border-radius: rem-calc(10);
    margin-bottom: rem-calc(20);
  }

  .list-items-group {

    &.selected {
      border-bottom: 1px solid $mystic;
      margin-bottom: rem-calc(9);
    }
  }

  .list-items-title {
    border-bottom: 1px solid $mystic;
    color: $primary-color;
    font-size: rem-calc(16);
    font-weight: $font-weight-semi-bold;
    line-height: rem-calc(21);
    letter-spacing: rem-calc(-0.89);
    margin-bottom: rem-calc(4);
    padding: rem-calc(8 28);
    text-transform: uppercase;
  }

  .list-item {
    padding: rem-calc(6 28);
  }

  .list-item-button {
    align-items: center;
    color: $dusty-gray;
    display: flex;
    font-size: rem-calc(14);
    font-weight: $font-weight-light;
    line-height: rem-calc(19);
    letter-spacing: rem-calc(-0.78);
    padding: 0;
    text-align: left;
    width: 100%;

    span {
      display: block;
      flex: 1;
      padding: rem-calc(0 10);
    }

    .svg-icon {
      flex-shrink: 0;
      height: rem-calc(20);
      width: rem-calc(20);

      svg {
        fill: $dusty-gray;
        height: rem-calc(20);
        width: rem-calc(20);
      }

      &:first-child {

      }

      &:last-child {

      }
    }
  }

  .high .list-item-button .svg-icon:first-child svg {
    fill: $error-color;
  }

  .moderate .list-item-button .svg-icon:first-child svg {
    fill: $warning-color;
  }

  .low .list-item-button .svg-icon:first-child svg {
    fill: $success-color;
  }
}

