.edit-form-screen {
  background-color: $athens-gray;
  &.edit-company {
    .profile-photo-header-wrapper {
      height: 100%;
      .photo-wrapper {
        height: rem-calc(36);
        width: rem-calc(36);
        border-radius: 0;
        &:not(.just-initials) {
          background-color: transparent;
        }
      }
    }
  }
}

.edit-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .submit {
    padding: rem-calc(16 0);
    margin: auto auto rem-calc(46);
  }

  .error-message {
    padding: rem-calc(0 16);
  }

  [type="submit"] {
    max-width: 100%;
    width: rem-calc(306);
  }
}
