.screen-loader-wrapper {
  background-color: rgba($black, 0.4);
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: map-get($zindex, loader);
}

.screen-loader {
  left: 50%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: rem-calc(44);

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  img {
    -webkit-animation: spin 2s ease-in-out infinite;
    -moz-animation: spin 2s ease-in-out infinite;
    animation: spin 2s ease-in-out infinite;
  }
  @-moz-keyframes spin{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-webkit-keyframes spin{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @keyframes spin {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  // @-moz-keyframes spin {
  //   100% {
  //     -moz-transform: rotateY(360deg);
  //   }
  // }
  // @-webkit-keyframes spin {
  //   100% {
  //     -webkit-transform: rotateY(360deg);
  //   }
  // }
  // @keyframes spin {
  //   100% {
  //     -webkit-transform: rotateY(360deg);
  //     transform: rotateY(360deg);
  //   }
  // }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  stroke: $eastern-blue;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .path {
    animation: dash 1.5s ease-in-out infinite;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }
}

.circular-bg {
  height: 100%;
  stroke: rgba($jumbo, 0.3);
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
