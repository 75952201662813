.global-button {
  background-color: $fountain-blue;
  border-radius: rem-calc(10);
  width: 100%;
  padding: rem-calc(10);
  .global-button-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .global-button-icon-wrapper {
      min-width: 10%;
      margin-right: rem-calc(8);
      .svg-icon {
        svg {
          height: rem-calc(20);
          width: rem-calc(20);
          fill: $white;
          --color-1: $white;
        }
      }
    }
    .global-button-label-wrapper {
      color: $white;
      text-transform: uppercase;
      font-size: rem-calc(15);
    }
  }
}
