.visitor-management-screen {
  &.page-hidden {
    display: none !important;
  }
  .screen-modal {
    &.qr-code-modal {
      z-index: 15;
    }
  }

  &.reception-desk-screen {
    flex: 1;
    container-type: inline-size;

    .screen-footer {
      display: flex;
      justify-content: center;

      .visitor-management-footer-menu {
        background: rgba($alabaster, 0.94);
        box-shadow: rem-calc(0 -0.5 0) rgba($black, 0.3);
        width: rem-calc(373);

        .menu-items {
          align-items: flex-start;
          display: flex;
          list-style: none;
          margin: 0;
          justify-content: space-evenly;
          padding: rem-calc(5 0 0 0);
        }

        .menu-item {
          font-size: rem-calc(10);
          line-height: rem-calc(12);
          width: rem-calc(50);

          &:only-child:not(.central-item) {
            margin-right: auto;
          }

          [href],
          button {
            color: $dusty-gray;
            display: block;
            font-size: rem-calc(10);
            padding: 0;
            margin: 0;
            text-align: center;
            text-decoration: none;
            width: 100%;

            .svg-icon {
              display: block;

              svg {
                height: rem-calc(25);
                width: rem-calc(25);
              }
            }

            &.active {
              color: $secondary-color;
            }

            &.disabled {
              cursor: not-allowed;
              opacity: 0.5;
            }
          }

          &.central-item {
            height: rem-calc(60);
            transform: translateY(rem-calc(-20));
            width: rem-calc(60);

            [href],
            button {
              align-items: center;
              background: linear-gradient(
                180deg,
                $eastern-blue 0%,
                $primary-color 100%
              );
              border-radius: 50%;
              color: rgba($white, 0.9);
              display: flex;
              font-size: rem-calc(15);
              height: rem-calc(60);
              letter-spacing: rem-calc(-0.83);
              line-height: rem-calc(20);
              justify-content: center;
              text-transform: uppercase;
              text-shadow: rem-calc(0 3 6) rgba($black, 0.16);
              width: rem-calc(60);

              .svg-icon {
                height: rem-calc(38);

                svg {
                  height: rem-calc(38);
                  width: rem-calc(38);
                }
              }

              &.disabled,
              &:disabled {
                background: $dusty-gray;
                cursor: not-allowed;
              }

              &.cancel {
                background: $white;
                padding: 0;

                .svg-icon {
                  height: rem-calc(60);

                  svg {
                    height: rem-calc(60);
                    width: rem-calc(60);
                  }
                }
              }
            }
          }
        }
      }
    }
    .reception-desk-modal-slide-wrapper {
      .reception-desk-modal-button-wrapper {
        .global-button {
          height: rem-calc(40);
          margin: rem-calc(5 0);
          &:first-child {
            margin-top: rem-calc(10);
          }
          &:last-child {
            margin-bottom: rem-calc(0);
          }
        }
      }
      .reception-desk-modal-message {
        color: $primary-color;
        padding: rem-calc(8);
      }
    }
  }

  .save-icon {
    &:hover {
      cursor: pointer;
    }
  }

  .visitors-body {
    position: relative;
    height: 100%;
    .modal-content {
      width: rem-calc(320);
    }

    .dialog-container {
      display: flex;
      flex: 1;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      margin: rem-calc(8);
      padding-top: rem-calc(8);

      &.dialog-hidden {
        display: none !important;
      }

      .dialog-action-buttons-container {
        justify-content: space-between;
        width: 100%;
        .dialog-status-message {
          padding: rem-calc(8) 0 rem-calc(8) 0;
          .success {
            color: $success-color;
          }

          .error {
            color: $error-color;
          }
        }
      }

      .dialog-info {
        width: 100%;
        margin-bottom: rem-calc(32);
        .dialog-text {
          justify-content: space-between;
          display: flex;
          color: $jumbo;
          line-height: rem-calc(24);
          color: $bright-gray;
          .dialog-text-label {
            font-weight: $font-weight-medium;
          }
          .dialog-text-content {
            margin-left: rem-calc(5);
            font-weight: $font-weight-bold;
          }
        }
      }

      .dialog-action-button {
        display: flex;
        color: $white;
        background-color: $primary-color;
        border: 1px solid $lightGrey;
        align-items: center;
        padding: rem-calc(8) rem-calc(24);
        border-radius: rem-calc(8);
        text-transform: uppercase;

        &:hover {
          cursor: pointer;
        }

        &:not(:first-of-type) {
          margin-top: rem-calc(12);
        }

        &:last-child {
          margin-bottom: rem-calc(12);
        }

        .svg-icon {
          margin-right: rem-calc(16);
          --color-1: #fff;
        }
      }

      .dialog-close {
        width: 100%;

        hr {
          margin: 0;
        }

        .dialog-close-action {
          color: $primary-color;
          font-weight: 600;
          padding-top: rem-calc(12);
          padding-bottom: rem-calc(8);
          text-transform: uppercase;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .visitors-section {
      width: 100%;

      .visitors-header {
        display: none;

        @container (#{map-get($grid-breakpoints, two)}) {
          display: block;
        }
      }
    }
  }

  .clebex-item-section {
    &.reduced-top-margin {
      margin: rem-calc(27 0 0 0);

      @container (#{map-get($grid-breakpoints, two)}) {
        margin: rem-calc(8 0 0 0);
      }
    }

    .clebex-item-section-item {
      .clebex-item-content-wrapper {
        &:hover {
          cursor: pointer;
        }
        .svg-icon-self-registered {
          --color-1: #ff3232;
        }
        .svg-icon-my-visitors {
          --color-1: #9d9d9d;
        }

        .visitor-edit-link {
          display: flex;
          width: 100%;
        }

        &.grey-bg {
          background: $whisper;
          font-weight: 500;

          div {
            &:first-child {
              width: rem-calc(55);
            }

            &:last-child {
              width: rem-calc(40);
            }
          }
        }

        .visitor-information {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex: 1;
          flex-direction: column;

          .label {
            padding: 0;
            overflow: visible;
            .highlight {
              &.tbd {
                color: $lightGrey;
              }
            }
          }

          @container (#{map-get($grid-breakpoints, two)}) {
            align-items: center;
            flex-direction: row !important;

            .label {
              padding: rem-calc(4);
            }
          }
        }
        .parent-name {
          font-size: rem-calc(16);
        }
      }

      a {
        text-decoration: none;
      }

      .follow-up-icons {
        align-items: center;
        .svg-icon {
          svg {
            width: rem-calc(18);
            height: rem-calc(18);
          }
        }
      }
    }

    &.no-space {
      margin-bottom: 1%;
    }
    &.no-top-margin {
      margin-top: 0rem;
    }
  }

  .visitors-options {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-bottom: none;

    @container (#{map-get($grid-breakpoints, two)}) {
      flex-direction: row !important;
      border-bottom: 1px solid $paynesGreyTransparent;
    }

    ::-webkit-calendar-picker-indicator {
      filter: invert(1) brightness(50%) sepia(100%) saturate(10000%)
        hue-rotate(90deg);
    }

    .visitor-selected-date {
      font-size: rem-calc(21);
      font-weight: 600;
      color: $primary-color;
      text-align: center;
      background-color: $primary-color-tint;
      box-shadow: none;
      display: flex;
      align-items: center;
      padding-left: rem-calc(0);
      justify-content: center;
      height: rem-calc(56);

      @container (#{map-get($grid-breakpoints, two)}) {
        width: rem-calc(200);
        padding-left: rem-calc(18);
        font-size: rem-calc(16);
      }

      &:hover {
        cursor: pointer;
      }
    }

    .visitor-calendar-wrapper {
      position: absolute;
      z-index: 1;
      max-width: rem-calc(375);
    }

    .visitors-calendar {
      width: 100%;
      font-size: 21px;
      font-weight: 600;
      color: $primary-color;
      text-align: center;
      background-color: $primary-color-tint;
      box-shadow: none;

      @container (#{map-get($grid-breakpoints, two)}) {
        width: rem-calc(200);
      }

      &:focus-visible {
        outline: none;
      }
    }

    .visitors-summary {
      display: flex;
      align-items: center;
      background-color: $primary-color-tint;
      padding: 0 10px;
      justify-content: space-between;
      min-height: rem-calc(46);
      min-width: rem-calc(325);
      border-top: 1px solid $paynesGreyTransparent;

      @container (#{map-get($grid-breakpoints, two)}) {
        border-top: none;
        padding: 0 40px 0 10px;
      }

      .visitors-summary-item {
        position: relative;

        &:hover {
          cursor: pointer;
        }
      }

      .visitor-count {
        position: absolute;
        top: 16px;
        right: 4px;
        height: rem-calc(18);
        min-width: rem-calc(18);
        background: $primary-color;
        color: $white;
        text-align: center;
        font-size: rem-calc(13);
        line-height: 18px;
        border-radius: 50%;

        &.active {
          background: $secondary-color;
        }
      }
    }

    .search-wrapper {
      width: 100%;
    }
  }
}
