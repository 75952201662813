.permissions {
  background-color: $primary-color;

  .logo {
    background-color: $white;
  }

  .steps-indicator {
    margin: 0 auto;
  }


  // youtube-tutorials
  .you-tube-tutorials .page-title-container {
    .svg-icon,
    svg {
      height: rem-calc(100);
      width: rem-calc(100);
    }
  }

  // manual
  .camera-access .page-title-container {
    .svg-icon,
    svg {
      height: rem-calc(88);
      width: rem-calc(100);
    }
  }
}
