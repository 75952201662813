.assign-resources,
.resources-screen {
  .clebex-pill-link {
    display: block;
    width: 100%;
    text-decoration: none !important;
  }

  .form-group {
    &.input {
      margin-top: 1.17rem;
      background-color: $white;
      border-top: 1px solid rgba(60, 60, 67, 0.29);
      border-bottom: 1px solid rgba(60, 60, 67, 0.29);

      input {
        height: rem-calc(36);
        border: none;
        padding: 0 0 0 1rem;
        color: #737373;
        -webkit-text-fill-color: #737373;
      }

      svg {
        fill: grey;
      }

      .tiny-label {
        color: $bright-gray;
        font-size: 17px;
        text-transform: none;
      }

      .assign-resource-label {
        padding: 0.4rem 0 0.4rem;
      }
    }
  }

  .clebex-item-content-wrapper {
    > .label {
      > .text {
        display: block;
        width: 100%;
      }
    }
  }

  .assign-resources-form {
    > .label {
      background-color: $athens-gray;
      border-bottom: 1px solid rgba($tuna, 0.29);
      color: rgba($tuna, 0.6);
      font-size: rem-calc(13);
      padding: rem-calc(13 16 4);
      letter-spacing: rem-calc(-0.1);
      line-height: rem-calc(21);
      text-transform: uppercase;
    }

    > .info {
      font-size: rem-calc(17);
      line-height: rem-calc(22);
      padding: rem-calc(0 16);

      [href],
      button:not([data-skip]) {
        display: block;
        padding: rem-calc(11 24 11 0);
        position: relative;
        text-decoration: none;
        width: 100%;

        .svg-icon,
        svg {
          fill: rgba($tuna, 0.3);
          height: rem-calc(26);
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate3d(rem-calc(4), -50%, 0);
          width: rem-calc(26);
        }
      }

      .label {
        color: $dusty-gray;
        text-align: left;

        [href],
        button {
          color: $dusty-gray;
        }

        span {
          display: block;
          padding: rem-calc(8 0);
        }

        &:only-child {
          width: 100%;
        }
      }

      .data {
        color: $bright-gray;
        text-align: right;

        [href],
        button {
          color: $bright-gray;
        }

        span {
          display: block;
          padding: rem-calc(11 0);
        }

        .checkbox {
          &.slide {
            margin-right: rem-calc(16);
          }
        }
      }

      &.editing {
        background-color: $athens-gray;
        padding-left: 0;
        padding-right: 0;

        .submit {
          margin-bottom: 0;
        }
      }
    }

    .assign-resource-label {
      align-items: center;
      border-bottom: 1px solid $french-gray;
      display: flex;
      width: 100%;

      &:last-child {
        border-bottom: 0;
      }

      .data {
        input {
          text-align: right;
        }
      }
    }
  }
  .profile-photo-header-wrapper {
    height: 100%;
    .photo-wrapper {
      height: rem-calc(28);
      width: rem-calc(28);
      border-radius: rem-calc(5);
    }
  }
}
