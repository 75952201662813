.realtime-screen {
  .realtime-map-icon {
    &.active {
      fill: #04bb40 !important;
      --color-2: #04bb40 !important;
    }
  }
  .clebex-section-input {
    .text-label {
      @include ellipsis;
      margin-top: 12px;
      &.disabled {
        fill: lightgray !important;
        -webkit-text-fill-color: lightgray !important;
      }
    }
  }
}
