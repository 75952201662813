.occupancy-screen {
  .help-content-wrapper {
    display: flex;
    width: 100%;
    position: relative;
    height: 100%;
    padding: rem-calc(15);
    background-color: white;
  }
  &.dashboard-screen {
    .screen-content {
      display: flex;
      flex-direction: column;
      background-color: $athens-gray;
      .gauges-wrapper {
        &:not(.single) {
          margin-top: rem-calc(170);
          flex-wrap: wrap;
        }
        &.single {
          border-top: 1px solid $mischa;
          justify-content: center;
          padding-top: rem-calc(10);
        }
        display: flex;
        .single-gauge-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 50%;
          position: relative;
          margin-top: rem-calc(10);
          padding: rem-calc(10 0);
          .gauge-label {
            position: absolute;
            top: 0;
            font-size: rem-calc(15);
            width: 100%;
            text-align: center;
          }
          .gauge-values {
            position: absolute;
            bottom: rem-calc(25);
            font-size: rem-calc(15);
            width: 100%;
            text-align: center;
            color: $bright-gray;
          }
        }
      }
    }
  }
  &.reports-screen {
    flex: 1;
    .screen-content {
      display: flex;
      flex-direction: column;
      margin-bottom: rem-calc(50);
      .chart-wrapper-y {
        position: absolute;
        top: rem-calc(280);
        height: calc(100% - 400px);
        overflow-x: hidden;
        width: rem-calc(40);
        background-color: $athens-gray;
        &.is-mobile {
          height: calc(100% - 344px);
          top: rem-calc(244);
        }
        &.is-windows:not(.is-mobile) {
          top: rem-calc(264);
        }
        .chart-wrapper {
          height: 100%;
        }
      }
      .chart-wrapper {
        flex-grow: 1;
        height: 100%;
        &.full-chart {
          margin-left: rem-calc(31);
          padding-top: rem-calc(200);
        }
        .chart-canvas {
          height: 100%;
          width: 100%;
        }
      }
    }
    .chart-legend-wrapper {
      z-index: 2;
      position: absolute;
      right: 0;
      top: rem-calc(250);
      .chart-legend-handle {
        cursor: pointer;
        background-color: $white;
        padding: rem-calc(5);
        border-radius: rem-calc(7 0 0 7);
        box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
        .svg-icon {
          svg {
            height: rem-calc(20);
            width: rem-calc(20);
            fill: $silverChalice;
            --color-6: $silverChalice;
          }
        }
        margin-top: rem-calc(30);
      }
      .chart-legend-content-wrapper {
        display: flex;
        align-items: center;
        .chart-legend-content {
          background-color: $white;
          padding: rem-calc(20 10);
          border-radius: rem-calc(7);
          box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
          .chart-legend-row {
            display: flex;
            align-items: center;
            &:not(:last-child) {
              margin-bottom: rem-calc(5);
            }
            .chart-legend-row-box {
              width: rem-calc(50);
              height: rem-calc(27);
              margin-right: rem-calc(10);
            }
          }
        }
        .chart-legend-handle {
          margin-top: 0;
        }
      }
      .slide-enter-active {
        transition: all 0.3s ease-out;
      }
      .slide-leave-active {
        transition: all 0.3s ease-in;
      }
      .slide-enter-from,
      .slide-leave-to {
        transform: translateX(rem-calc(30));
      }
    }
    .clebex-item-section.technical-list {
      &:last-child {
        margin-top: 0;
      }
      &:not(.technical-list-header) {
        overflow-y: auto;
        border-radius: rem-calc(0 0 10 10);
      }
      &.technical-list-header {
        border-radius: rem-calc(10 10 0 0);
        margin-bottom: 0;
        .clebex-item-column {
          font-weight: $font-weight-bold;
        }
      }
      .clebex-item-section-item {
        overflow: visible;
        &::after {
          left: 0;
        }
        .clebex-item-content-wrapper {
          .clebex-item-column {
            font-size: rem-calc(14);
          }
        }
      }
    }
    .clebex-item-section.technical-list {
      &.filters-open {
        @media only screen and (max-width: rem-calc(1398.99)) {
          background-color: transparent;
          border-radius: 0;
          box-shadow: none;
          margin: rem-calc(27 0 27 0);
          &.technical-list-header {
            display: none;
          }
          .clebex-item-section-item {
            background: transparent;
            padding: rem-calc(0 16);
            margin-bottom: rem-calc(10);
            overflow: visible;
            &::after {
              display: none !important;
            }
          }
          .clebex-item-content-wrapper {
            flex-direction: column;
            align-items: flex-start;
            background-color: $white;
            border-radius: rem-calc(10);
            box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
            padding: rem-calc(10);
            .clebex-item-column {
              color: $dusty-gray;
              &:nth-child(4) {
                font-size: rem-calc(15);
                color: $bright-gray;
                order: 1;
                margin-bottom: rem-calc(10);
              }
              &:not(:nth-child(4)) {
                order: 2;
                &:not(:last-child) {
                  margin-bottom: rem-calc(5);
                }
              }
              span {
                color: $bright-gray;
              }
            }
          }
        }
      }
      &:not(.filters-open) {
        @media only screen and (max-width: rem-calc(1023.99)) {
          background-color: transparent;
          border-radius: 0;
          box-shadow: none;
          margin: rem-calc(27 0 27 0);
          &.technical-list-header {
            display: none;
          }
          .clebex-item-section-item {
            background: transparent;
            padding: rem-calc(0 16);
            margin-bottom: rem-calc(10);
            overflow: visible;
            &::after {
              display: none !important;
            }
          }
          .clebex-item-content-wrapper {
            flex-direction: column;
            align-items: flex-start;
            background-color: $white;
            border-radius: rem-calc(10);
            box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
            padding: rem-calc(10);
            .clebex-item-column {
              color: $dusty-gray;
              &:nth-child(4) {
                font-size: rem-calc(15);
                color: $bright-gray;
                order: 1;
                margin-bottom: rem-calc(10);
              }
              &:not(:nth-child(4)) {
                order: 2;
                &:not(:last-child) {
                  margin-bottom: rem-calc(5);
                }
              }
              span {
                color: $bright-gray;
              }
            }
          }
        }
      }
    }
    .clebex-item-section.technical-list {
      &.filters-open {
        @media only screen and (min-width: rem-calc(1399)) {
          .clebex-item-section-item {
            border-radius: 0;
            &::after {
              left: 0;
              border-bottom: rem-calc(1) solid $athens-gray;
            }
            .clebex-item-content-wrapper {
              .clebex-item-column {
                padding: rem-calc(10);
                color: $bright-gray;
                &:nth-child(1) {
                  width: 20%;
                }
                &:nth-child(2) {
                  width: 10%;
                }
                &:nth-child(3) {
                  width: 15%;
                }
                &:nth-child(4) {
                  width: 15%;
                }
                &:nth-child(5) {
                  width: 5%;
                }
                &:nth-child(6) {
                  width: 20%;
                }
                &:nth-child(7) {
                  width: 15%;
                }
                span {
                  display: none;
                }
              }
            }
          }
          &:not(.technical-list-header) {
            .clebex-item-section-item:nth-child(odd) {
              background-color: $primary-color-tint;
            }
          }
        }
      }
      &:not(.filters-open) {
        @media only screen and (min-width: rem-calc(1024)) {
          .clebex-item-section-item {
            border-radius: 0;
            &::after {
              left: 0;
              border-bottom: rem-calc(1) solid $athens-gray;
            }
            .clebex-item-content-wrapper {
              .clebex-item-column {
                padding: rem-calc(10);
                color: $bright-gray;
                &:nth-child(1) {
                  width: 20%;
                }
                &:nth-child(2) {
                  width: 10%;
                }
                &:nth-child(3) {
                  width: 15%;
                }
                &:nth-child(4) {
                  width: 15%;
                }
                &:nth-child(5) {
                  width: 5%;
                }
                &:nth-child(6) {
                  width: 20%;
                }
                &:nth-child(7) {
                  width: 15%;
                }
                span {
                  display: none;
                }
              }
            }
          }
          &:not(.technical-list-header) {
            .clebex-item-section-item:nth-child(odd) {
              background-color: $primary-color-tint;
            }
          }
        }
      }
    }
    .screen-content {
      background-color: $athens-gray;
    }
    .screen-footer {
      display: flex;
      justify-content: center;
      .occupancy-footer-menu {
        width: rem-calc(373);
        height: 100%;
      }
    }
  }
  &.occupancy-settings {
    .clebex-item-section-item {
      &.prediction-days {
        .clebex-item-dl {
          .clebex-item-dt {
            flex-grow: 1;
            color: $bright-gray;
          }
          .clebex-item-dd {
            width: rem-calc(45);
            input {
              padding: 0;
              color: $bright-gray;
            }
          }
        }
      }
      &.color-picker {
        .follow-up-icon-item {
          width: rem-calc(50);
          height: rem-calc(30);
          input {
            cursor: pointer;
            height: 100%;
            width: 100%;
            padding: 0;
            box-shadow: none;
            background-color: transparent;
          }
          input::-webkit-color-swatch {
            border: none;
          }
        }
      }
      .follow-up-icon-item {
        margin-right: rem-calc(10);
        font-size: rem-calc(14);
      }
    }
  }
  &:not(.technical-list-screen) {
    .search-refresh {
      position: absolute;
      width: rem-calc(341);
      align-self: center;
      margin-top: rem-calc(170);
      &.calendar-expanded {
        margin-top: rem-calc(490);
      }
    }
  }
  &.technical-list-screen {
    .search-refresh {
      margin-top: rem-calc(16);
    }
  }
  .screen-content {
    .calendar {
      position: absolute;
      z-index: 2;
      width: rem-calc(341);
      align-self: center;
      &.calendar-picker {
        margin-top: rem-calc(170);
      }
      .clebex-item-section-item {
        background-color: $white;
        &:not(.collapsed):after {
          right: rem-calc(16);
        }
      }
    }
  }
  .clebex-item-section.search-refresh {
    z-index: 2;
    .follow-up-icon-item {
      cursor: pointer;
      margin-right: rem-calc(10);
      svg {
        width: rem-calc(28);
        height: rem-calc(28);
      }
    }
  }
  .filters-changed-overlay {
    pointer-events: inherit;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $black;
    opacity: 0.4;
    z-index: 1;
  }
  .day-of-the-week {
    color: $dusty-gray !important;
    background-color: $white !important;
  }
  .clebex-item-content-range {
    padding: rem-calc(0 12);
    .clebex-item-range {
      display: flex;
      align-items: center;
      &:not(.separator) {
        width: rem-calc(138);
        @include ellipsis;
      }
      &.to {
        justify-content: right;
      }
      .svg-icon {
        width: rem-calc(16);
        height: rem-calc(16);
      }
      .clebex-item-range-cta {
        margin-left: rem-calc(3);
      }
    }
  }
  .occupancy-footer-menu {
    .menu-items {
      justify-content: space-evenly;
      .menu-item:not(.central-item) {
        svg {
          --color-2: #737373;
        }
      }
    }
  }
  .occupancy-header {
    .filter-icon {
      --color-2: white;
    }
  }
}
