.screen-modal {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: map-get($zindex, screen-modal);

  .modal-backdrop {
    background-color: rgba($black, 0.4);
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.info-modal {
    z-index: 11;
    .modal-footer {
      padding-bottom: 0;
      justify-content: center;
      .modal-btn {
        border: none;
      }
    }
  }

  &.slider-modal {
    .modal-content-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      align-items: center;
      .modal-slider-arrow {
        z-index: 1;
        width: rem-calc(14);
        height: rem-calc(20);
        cursor: pointer;
        .svg-icon {
          --color-2: #ffffff;
        }
      }
      .modal-content {
        width: 80%;
        max-width: rem-calc(268);
        margin: rem-calc(0 20);
        overflow: visible;
        background-color: $concrete;
        .modal-inner-content {
          overflow: hidden;
          padding: 0;
          margin: rem-calc(10);
        }
        .modal-footer {
          padding-bottom: 0;
          justify-content: center;
          .modal-btn {
            font-weight: $font-weight-regular;
            border: none;
          }
        }
      }
      .modal-slider-number {
        position: absolute;
        bottom: rem-calc(-30);
        width: 100%;
        color: $white;
        z-index: 1;
        font-size: rem-calc(17);
      }
    }
  }

  &.confirm-modal {
    .modal-footer {
      padding-bottom: 0;

      @media #{$touch-device-query} {
        padding-bottom: 0;
      }
    }
  }

  .modal-header {
    padding: rem-calc(25 0 15);

    .svg-icon,
    svg {
      display: block;
      height: rem-calc(100);
      margin: auto;
      width: rem-calc(100);
    }

    + .modal-content .modal-inner-content {
      padding-top: 0;
    }
  }

  .modal-content {
    background-color: rgba($concrete, 0.8);
    border-radius: rem-calc(14);
    backdrop-filter: blur(rem-calc(20));
    -webkit-backdrop-filter: blur(rem-calc(20));
    font-size: rem-calc(13);
    line-height: rem-calc(16);
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    position: relative;
    text-align: center;
    width: rem-calc(268);
    z-index: map-get($zindex, screen-modal);
  }

  .modal-inner-content {
    padding: rem-calc(18 16);
  }

  .modal-title {
    font-size: rem-calc(17);
    font-weight: $font-weight-semi-bold;
    letter-spacing: rem-calc(-0.54);
    line-height: rem-calc(22);
    margin: 0;
  }

  .modal-footer {
    align-items: center;
    border-top: 1px solid rgba($tuna, 0.29);
    display: flex;
    padding-bottom: rem-calc(8);
  }

  .modal-btn {
    color: $primary-color;
    padding: rem-calc(11);
    font-size: rem-calc(17);
    letter-spacing: rem-calc(-0.41);
    line-height: rem-calc(22);
    width: 50%;

    &:last-child {
      border-left: 1px solid rgba($tuna, 0.29);
      font-weight: $font-weight-semi-bold;
    }
  }

  &.success {
    svg {
      fill: $success-color;
    }
  }

  &.warning {
    svg {
      fill: $error-color;
    }
  }

  &.wide {
    .modal-content {
      width: rem-calc(300);
    }
  }
  .scan-button {
    background-color: $white;
    border-radius: rem-calc(4);
    color: $bright-gray;
    padding: rem-calc(10);
    margin: rem-calc(5 0);
  }

  &.qr-code-modal,
  &.camera-modal {
    .modal-content {
      background-color: rgba($alabaster, 0.94);
      backdrop-filter: none;
      border-radius: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .modal-inner-content {
      background-color: $black;
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      padding: 0;

      .video {
        flex: 90%;
      }

      #take-photo-canvas {
        width: 373px;
        height: 280px;
        display: none;
      }

      .clebex-take-photo-container {
        flex: 10%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .clebex-take-photo-ring {
          border: 2px solid $white;
          border-radius: 50%;
          height: rem-calc(100);
          padding: rem-calc(4);
          width: rem-calc(100);
          margin-bottom: rem-calc(4);

          .take-photo {
            background: $white;
            color: $bright-gray;
            font-size: rem-calc(16);
            padding: rem-calc(12);
            border-radius: 50%;
            align-items: center;
            height: rem-calc(88);
            width: rem-calc(88);
          }
        }
      }
    }

    .reader {
      width: 100%;
    }
  }

  .modal-footer {
    padding-bottom: rem-calc(25);

    @media #{$touch-device-query} {
      padding-bottom: rem-calc(4);
    }

    .actions-menu {
      width: 100%;
    }
  }

  .cameras {
    align-items: center;
    background-color: $black;
    bottom: rem-calc(70);
    display: none;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;

    &.active {
      display: flex;
      flex-direction: column;
    }
  }
}
