.header-selected-space-location {
  background-color: rgba($primary-color, 0.05);
  color: $dusty-gray;
  font-size: rem-calc(15);
  padding: rem-calc(0 0 10 30);

  .svg-icon {
    display: inline-block;
    height: rem-calc(20);
    vertical-align: middle;
    width: rem-calc(15);

    svg {
      fill: $dusty-gray;
      height: rem-calc(20);
      width: rem-calc(15);
    }
  }
}
