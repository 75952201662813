.outlook-screen {
  .screen-header {
    .outlook-header {
      height: rem-calc(40);
      padding: rem-calc(8 0);
      a,
      button,
      div {
        height: auto;
      }
    }
  }
  .clebex-item-section {
    .label {
      div {
        font-size: rem-calc(17);
      }
    }
    .svg-icon {
      fill: $secondary-color;
      .warning {
        fill: $warning-color;
      }
      .error {
        fill: $error-color;
      }
    }
    .clebex-item-section-item {
      .clebex-item-content-wrapper {
        min-height: auto;
        .attendees-warning-wrapper {
          display: flex;
          justify-items: center;
          align-items: center;
          .warning-count-wrapper {
            margin-left: rem-calc(12);
            .warning-count-number {
              color: $fountain-blue;
              span {
                color: $warning-color;
              }
            }
            .svg-icon {
              width: rem-calc(12);
              height: rem-calc(12);
            }
          }
        }
        .resources-warning-wrapper {
          padding: rem-calc(4 16 2 2);
        }
        .warning-count-wrapper {
          display: flex;
          border: rem-calc(1) solid $primary-color;
          border-radius: rem-calc(4);
          margin: rem-calc(4);
          padding: rem-calc(6);
          color: $primary-color;
          font-size: rem-calc(15);
          height: rem-calc(22);
          align-items: center;
          .warning-count-number {
            line-height: rem-calc(22);
          }
          .svg-icon {
            fill: $fountain-blue;
            margin: rem-calc(0 0 4 2);
            width: rem-calc(10);
            height: rem-calc(10);
          }
        }
      }
      &.attendees {
        padding-bottom: rem-calc(10);
        .clebex-item-content-wrapper {
          flex-wrap: wrap;
          padding: rem-calc(0 16 0 16);
          .attendee {
            border: rem-calc(0.5) solid $red-orange;
            border-radius: rem-calc(20);
            font-size: rem-calc(15);
            font-weight: $font-weight-medium;
            color: $dusty-gray;
            background-color: $chablis;
            padding: rem-calc(6);
            margin: rem-calc(8 2 0 0);
            display: flex;
            align-items: center;
            .svg-icon {
              cursor: pointer;
              height: rem-calc(16);
              width: rem-calc(16);
              margin-right: rem-calc(4);
              svg {
                --color-4: #999999;
              }
            }
          }
        }
      }
      &.resources-section {
        padding: rem-calc(5 0);
        margin-bottom: rem-calc(5);
        .clebex-item-content-wrapper {
          .label {
            align-self: flex-end;
          }
        }
      }
      &.resources {
        padding-bottom: rem-calc(4);
        .resource-wrapper {
          display: flex;
          align-items: center;
          margin: rem-calc(4 16 4 16);
          border: rem-calc(0.5) solid $silver;
          border-radius: rem-calc(10);
          font-size: rem-calc(14);
          color: $dusty-gray;
          height: rem-calc(40);
          &.disabled {
            background-color: $chablis;
          }
          &.add-resource {
            background-color: $polar;
            .svg-icon {
              &:not(.no-pointer-events) {
                cursor: pointer;
              }
              transform: rotate(45deg);
              svg {
                --color-4: #4cc2bd;
              }
            }
            .resource-name {
              color: $primary-color;
              font-weight: $font-weight-medium;
            }
          }
          .svg-icon {
            flex-shrink: 0;
            cursor: pointer;
            margin: rem-calc(6);
            height: rem-calc(20);
            width: rem-calc(20);
            svg {
              --color-4: #999999;
            }
          }
          .resource-name {
            color: $bright-gray;
            margin: rem-calc(4 4 4 0);
            span {
              color: $dusty-gray;
            }
          }
        }
      }
      &.attendee-slots {
        margin-top: rem-calc(8);
        .slot-wrapper {
          position: relative;
          margin: rem-calc(0 16 8 16);
          display: inline-flex;
          align-items: center;
          border: rem-calc(1) solid $silver;
          border-radius: rem-calc(4);
          .attendee-number-wrapper {
            position: absolute;
            height: calc(100% + 2px);
            left: rem-calc(-1);
            padding: rem-calc(3);
            display: flex;
            align-items: center;
            border: rem-calc(1) solid $primary-color;
            border-radius: rem-calc(4);
            color: $primary-color;
            font-size: rem-calc(14);
            .svg-icon {
              fill: $fountain-blue;
              margin: rem-calc(0 0 2 2);
              width: rem-calc(10);
              height: rem-calc(10);
            }
          }
          .time-slot {
            margin-left: rem-calc(30);
            padding: rem-calc(4);
            color: $dusty-gray;
            font-weight: $font-weight-medium;
            font-size: rem-calc(16);
          }
        }
      }
      &.advanced-search {
        .body-btn {
          text-transform: uppercase;
          font-weight: $font-weight-medium;
          .svg-icon {
            margin-right: rem-calc(8);
          }
        }
      }
      &.title {
        .clebex-section-input-label {
          font-size: rem-calc(17) !important;
          span {
            color: $primary-color;
          }
        }
        input {
          font-size: rem-calc(17) !important;
          color: $dusty-gray;
        }
      }
      &.warning-center {
        .clebex-item-content-wrapper {
          .label {
            align-self: flex-end;
          }
          .warning-center {
            color: $secondary-color;
          }
        }
      }
      &.duration {
        height: rem-calc(30);
        .clebex-pill-link {
          height: 100%;
        }
        .clebex-item-content-wrapper {
          height: 100%;
          .label {
            align-self: flex-end;
          }
          .warning-count-wrapper {
            .warning-count-number {
              color: $fountain-blue;
            }
          }
        }
      }
      &.attendees-section {
        .clebex-item-content-wrapper {
          .label {
            align-self: flex-end;
          }
          .warning-count-number {
            color: $fountain-blue;
          }
        }
      }
      &.warning-line {
        .clebex-section-input-label {
          font-size: rem-calc(15) !important;
          span {
            color: $bright-gray;
            font-weight: $font-weight-bold;
          }
        }
        .follow-up-icons {
          align-self: flex-start;
        }
        .resource-name {
          font-weight: $font-weight-medium;
        }
      }
      svg.red {
        fill: $red-orange;
        --color-6: white;
      }
      svg.yellow {
        fill: black;
        --color-5: #f0be00;
      }
      &.recurrence {
        height: rem-calc(40);
        .clebex-item-content-wrapper {
          height: 100%;
          .clebex-section-input-label {
            font-size: rem-calc(17);
          }
          .recurrence-warning-wrapper {
            display: flex;
            align-items: center;
          }
        }
      }
      .clebex-item-content-range {
        padding: rem-calc(4 16);
        justify-content: center;
        height: rem-calc(40);
        .separator {
          margin: rem-calc(0 16);
          .svg-icon {
            height: rem-calc(20);
            width: rem-calc(20);
          }
        }
      }
    }
    &.duration-wrapper {
      .duration {
        margin-bottom: rem-calc(5);
      }
      .time-selection-wrapper {
        padding: rem-calc(5 16);
        .clebex-item-content-wrapper {
          align-items: center;
          justify-content: space-between;
          display: flex;
          .title {
            font-size: rem-calc(17);
            width: 25%;
          }
          .time-selection {
            display: flex;
            width: 75%;
            align-items: center;
            overflow-x: auto;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
              display: none;
            }
            .time {
              height: 100%;
              flex-shrink: 0;
              border: rem-calc(0.5) solid $dusty-gray;
              border-radius: rem-calc(16);
              font-size: rem-calc(14);
              font-weight: $font-weight-medium;
              color: $dusty-gray;
              margin: rem-calc(0 4);
              padding: rem-calc(0 4);
              line-height: rem-calc(30);
              cursor: pointer;

              &.hours-selected {
                color: $white;
                background-color: $fountain-blue;
              }
              &.minutes-selected {
                color: $white;
                background-color: $hopbush;
              }
            }
          }
        }
      }
    }
    &.advanced-search {
      box-shadow: none;
      .clebex-item-section-item {
        .clebex-item-content-wrapper {
          justify-content: space-between;
          .selection-buttons-wrapper {
            height: rem-calc(50);
            display: flex;
            flex-grow: 1;
            margin-right: rem-calc(10);
            background-color: $white;
            border-radius: rem-calc(10);
            padding: rem-calc(6);
            .selection-buttons {
              border-radius: rem-calc(10);
              padding: rem-calc(2);
              background-color: $athens-gray-alt;
              display: flex;
              flex-grow: 1;
              &.people .body-btn:nth-child(2)::before {
                display: none;
              }
              &.time .body-btn:nth-child(2) {
                &::before,
                &::after {
                  display: none;
                }
              }
              &.resource .body-btn:nth-child(2)::after {
                display: none;
              }
              .body-btn {
                svg {
                  fill: $silverChalice;
                }
                position: relative;
                height: auto;
                &:not(:last-child) {
                  margin-right: rem-calc(2);
                }
                &:not(.selected) {
                  background-color: $athens-gray-alt;
                  box-shadow: none;
                }
                &:nth-child(2) {
                  &::before {
                    border-left: 1.5px solid $silver;
                    top: 0;
                    left: 0;
                    content: "";
                    display: block;
                    position: absolute;
                    height: rem-calc(18);
                    margin-top: rem-calc(9);
                  }
                  &::after {
                    border-right: 1.5px solid $silver;
                    top: 0;
                    right: 0;
                    content: "";
                    display: block;
                    position: absolute;
                    height: rem-calc(18);
                    margin-top: rem-calc(9);
                  }
                }
              }
            }
          }
          .one-click {
            width: rem-calc(70);
            height: rem-calc(50);
          }
        }
      }
    }
    &.location-wrapper {
      padding-bottom: rem-calc(5);
      .clebex-item-section-item {
        &.location-section {
          padding: rem-calc(5 0);
        }
        &.level {
          margin: rem-calc(5 10);
          border-radius: rem-calc(10);
          border: rem-calc(0.5) solid $silver;
          &::after {
            border-bottom: none !important;
          }
          .clebex-item-content-wrapper {
            .clebex-section-input {
              text-align: left;
              .clebex-section-input-label {
                padding-left: 0;
                span {
                  color: $dusty-gray;
                }
              }
            }
          }
        }
      }
      .levels {
        max-height: rem-calc(144);
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    &.types-wrapper {
      padding-bottom: rem-calc(5);
      .clebex-item-section-item {
        &.types-section {
          padding: rem-calc(5 0);
        }
        &.types {
          margin: rem-calc(5 10);
          .clebex-item-content-wrapper {
            border: none;
            flex-wrap: wrap;
            .type {
              border: rem-calc(0.5) solid $silver;
              border-radius: rem-calc(20);
              padding: rem-calc(2 5 2 2);
              display: flex;
              align-items: center;
              margin: rem-calc(0 5 5 0);
              &.selected {
                background-color: $polar;
              }
              &:not(.selected) {
                cursor: pointer;
                padding: rem-calc(2 5);
              }
              .svg-icon {
                margin-right: rem-calc(5);
                height: rem-calc(18);
                width: rem-calc(18);
                cursor: pointer;
                --color-4: #999999;
              }
              .type-title {
                line-height: rem-calc(18);
                color: $silverChalice;
              }
            }
          }
        }
      }
    }
    &.attributes-wrapper {
      padding-bottom: rem-calc(5);
      .clebex-item-section-item {
        &.attributes-section {
          padding: rem-calc(5 0);
        }
        &.attributes {
          margin: rem-calc(5 10);
          .clebex-item-content-wrapper {
            border: none;
            flex-wrap: wrap;
            .attribute {
              border: rem-calc(0.5) solid $silver;
              border-radius: rem-calc(20);
              padding: rem-calc(2 5 2 2);
              display: flex;
              align-items: center;
              margin: rem-calc(0 5 5 0);
              &.selected {
                background-color: $polar;
              }
              &:not(.selected) {
                cursor: pointer;
                padding: rem-calc(2 5);
              }
              .svg-icon {
                margin-right: rem-calc(5);
                height: rem-calc(18);
                cursor: pointer;
                --color-4: #999999;
              }
              .attribute-title {
                line-height: rem-calc(18);
                color: $silverChalice;
              }
            }
          }
        }
      }
    }
    &.suggested-resources {
      padding-bottom: rem-calc(5);
      .svg-icon {
        &:not(.no-pointer-events) {
          cursor: pointer;
        }
        transform: rotate(45deg);
        svg {
          --color-4: #4cc2bd;
        }
      }
    }
    &.capacity-wrapper {
      .clebex-item-content-wrapper {
        .clebex-section-input-label {
          font-size: rem-calc(15);
        }
        .follow-up-icons {
          .follow-up-icon-item {
            height: rem-calc(26);
            width: rem-calc(26);
            line-height: rem-calc(26);
            border-radius: rem-calc(7);
            background-color: $fountain-blue;
            font-size: rem-calc(26);
            text-align: center;
            color: white;
            margin-right: rem-calc(10);
            cursor: pointer;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome, Edge, Opera and Firefox */
          }
        }
      }
    }
    &.recurrence-wrapper {
      .clebex-item-section-item {
        padding: rem-calc(5);
        &.recurrence-selection {
          .clebex-item-content-wrapper {
            justify-content: space-between;
            .recurrence-period {
              padding: rem-calc(5);
              margin: rem-calc(5);
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 50%;
              border: rem-calc(0.5) solid $silver;
              border-radius: rem-calc(10);
              background-color: $footer-color-no-transparency;
              box-shadow: rem-calc(0 3 6) rgba($black, 0.16);
              height: rem-calc(30);
              .svg-icon {
                fill: $primary-color;
              }
            }
          }
        }
      }
    }
    &.start-end-date {
      .clebex-section-input-label {
        font-size: rem-calc(15);
        &.date {
          font-size: rem-calc(17);
          color: $dusty-gray;
        }
      }
    }
  }
}
