.help-screen {
  .screen-content {
    background-color: white;
    .help-content-wrapper {
      display: flex;
      width: 100%;
      position: relative;
      height: 100%;
      padding: rem-calc(15);
      background-color: white;
    }
  }
}
