.clebex-calendar {
  border-bottom: 1px solid rgba($primary-color, 0.1);
  overflow: hidden;

  .calendar-header {
    align-items: center;
    background-color: $polar;
    display: flex;
    justify-content: space-between;
    padding: rem-calc(10 22 0 30);
    width: 100%;
  }

  .month-switcher {
    display: flex;
    justify-content: space-between;
    width: rem-calc(70);

    .month {
      align-items: center;
      background-color: $eastern-blue;
      border-radius: 50%;
      color: $white;
      display: flex;
      height: rem-calc(20);
      justify-content: center;
      overflow: hidden;
      padding: 0;
      white-space: nowrap;
      width: rem-calc(20);

      .svg-icon {
        height: rem-calc(18);
        width: rem-calc(18);
      }

      svg {
        fill: $white;
        height: rem-calc(18);
        width: rem-calc(18);
      }
    }
  }

  .calendar-title {
    color: $primary-color;
    font-size: rem-calc(24);
    font-weight: $font-weight-semi-bold;
    letter-spacing: rem-calc(-0.58);
    text-transform: uppercase;

    span {
      color: rgba($primary-color, 0.6);
    }
  }

  .calendar-wrapper {
    display: block;
    font-size: rem-calc(13);
    line-height: rem-calc(18);
    overflow: hidden;
    position: relative;
  }

  .days-of-the-week-labels {
    background-color: $polar;
    align-items: center;
    color: $eastern-blue;
    display: flex;
    list-style: none;
    margin: 0;
    padding: rem-calc(10 0 0);
  }

  .day-label {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: rem-calc(34);
    justify-content: center;
    width: 1 / 7 * 100%;
  }

  .days-of-the-week {
    background-color: $polar;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: rem-calc(0 0 7);
  }
  .header-calendar {
    .days-of-the-week {
      padding: rem-calc(0 0 12);
    }
  }

  .day-button {
    align-items: center;
    background-color: transparent;
    border-radius: rem-calc(4);
    appearance: none;
    box-shadow: none;
    border: 0;
    color: $primary-color;
    display: flex;
    font-weight: $font-weight-regular;
    height: rem-calc(34);
    justify-content: center;
    width: rem-calc(34);

    &.disabled {
      color: $dusty-gray;
    }
  }

  .day {
    display: flex;
    flex-wrap: wrap;
    height: rem-calc(34);
    margin: rem-calc(7 0);
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    position: relative;
    width: 1 / 7 * 100%;

    &.today .day-button {
      background-color: rgba($primary-color, 0.3);
      color: $white;
    }

    &.selected:not(.date-range) {
      .day-button:not(.disabled) {
        background-color: rgba($primary-color, 0.7);
        color: $white;
      }

      &.occupancy::before {
        background-color: $white;
      }
    }

    &.occupancy {
      &.low .day-button {
        border: 2px solid $success-color;
      }

      &.moderate .day-button {
        border: 2px solid $warning-color;
      }

      &.high .day-button {
        border: 2px solid $error-color;
      }

      &::before {
        background-color: $primary-color;
        border-radius: 50%;
        bottom: rem-calc(4);
        content: "";
        cursor: pointer;
        display: none;
        height: rem-calc(4);
        position: absolute;
        width: rem-calc(4);
      }

      &.has-occupancy::before {
        display: block;
      }
    }

    &.first-in-range {
      .day-button {
        background-color: rgba($primary-color, 0.7);
        color: $white !important;
      }

      + .in-range:not(.no-range)::before {
        background: #24b7af4d;
        bottom: rem-calc(2);
        content: "";
        display: block;
        position: absolute;
        right: 100%;
        top: rem-calc(2);
        width: rem-calc(8);
      }
    }

    &.in-range {
      &:first-child:not(.first-in-range),
      + .in-range:not(.no-range) {
        box-shadow: inset 0px 2px 0px 0px $white, inset 0px -2px 0px 0px $white;
        background: #24b7af4d;

        .day-button {
          background-color: transparent;
          color: $primary-color;
        }
      }

      + .last-in-range {
        &:not(.no-range)::before {
          background: #24b7af4d;
          bottom: rem-calc(2);
          content: "";
          display: block;
          left: 0;
          position: absolute;
          top: rem-calc(2);
          width: rem-calc(8);
        }

        .day-button {
          background-color: rgba($primary-color, 0.7);
          color: $white !important;
        }
      }

      &.first-in-range + .last-in-range::before {
        left: rem-calc(-8);
        width: rem-calc(16);
      }
    }
  }

  .expander {
    border: 0;
    bottom: rem-calc(5);
    height: rem-calc(12);
    width: rem-calc(12);
    left: 50%;
    margin: 0;
    padding: 0;
    position: absolute;
    transform: translateX(-50%);
    svg {
      transform: rotate(90deg);
      --color-2: #b3b6b6;
    }
    &.expanded {
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  &.field-selection {
    .calendar-header {
      background-color: $white;
      padding: rem-calc(6 22 6 30);
    }

    .days-of-the-week-labels,
    .days-of-the-week {
      background-color: $white;
    }

    .calendar-title {
      font-weight: $font-weight-regular;
    }

    .day {
      margin: 0;
    }
  }
}
