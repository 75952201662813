.main-navigation {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;

  &.theme-company {
    .main-nav-header {
      background-color: rgba($primary-color, 0.8);
      color: $white;

      .svg-icon {
        fill: $white;
      }

      .initials {
        align-items: center;
        background-color: $primary-color-lighter;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        height: rem-calc(36);
        justify-content: center;
        width: rem-calc(36);

        &.round {
          border-radius: 50%;
        }

        &.photo-added {
          background-color: transparent;
        }
      }
    }
  }

  &.theme-empire {
    .main-nav-header {
      background-color: $empire-color;
      color: $white;

      .svg-icon {
        fill: $white;
      }
    }
  }

  &.theme-alternate {
    .main-nav-header {
      background-color: $alternate-login;
      color: $white;

      .svg-icon {
        fill: $white;
      }
    }
  }

  .main-nav-header {
    flex-shrink: 0;

    .toggle-menu {
      display: flex;

      @media (#{map-get($grid-breakpoints, one)}) {
        display: none;
      }
    }
  }

  .menu-items {
    flex: 1 1 auto;
    overflow: auto;
  }

  .menu-section {
    list-style: none;
    margin: 0;
    padding: rem-calc(15 0 0);

    &:last-of-type li:last-child .block-list-item-button::after {
      display: none;
    }

    &.back-to-main {
      padding: 4px 0;
      background-color: $black-squeeze;
      span {
        color: rgba($color: $tuna, $alpha: .6);
        font-size: 16px;
      }
      .svg-icon {
        width: 20px;
        height: 20px;
        top: 10px;
        left: 10px;
        background-color: $primary-color;
        border-radius: 50%;
        svg {
          --color-1: #ffffff;
        }
      }
      .block-list-item-button::after {
        border: none;
      }
    }
  }

  .menu-section-name {
    color: rgba($tuna, 0.6);
    font-size: rem-calc(13);
    line-height: rem-calc(21);
    padding-left: rem-calc(16);
    text-transform: uppercase;
  }

  .menu-item {
    font-size: rem-calc(17);
    line-height: rem-calc(22);

    //[href] {
    //  align-items: center;
    //  border-bottom: 1px solid rgba($tuna, 0.29);
    //  color: $bright-gray;
    //  display: flex;
    //  margin-left: rem-calc(40);
    //  padding: rem-calc(9 0);
    //  position: relative;
    //  text-decoration: none;
    //}

    //.icon {
    //  align-items: center;
    //  display: flex;
    //  height: rem-calc(40);
    //  justify-content: center;
    //  width: rem-calc(40);
    //
    //  &:first-child {
    //    left: 0;
    //    position: absolute;
    //    transform: translateX(-100%);
    //    top: 0;
    //  }
    //
    //  &:last-child:not(:first-child) {
    //    margin-left: auto;
    //    flex-shrink: 0;
    //    height: rem-calc(22);
    //  }
    //}
    //
    //.svg-icon {
    //  height: rem-calc(20);
    //}
    //
    //svg {
    //  fill: $dusty-gray;
    //  height: rem-calc(20);
    //  width: rem-calc(20);
    //}
  }

  .screen-footer {
    @extend .screen-footer;
    position: relative;
  }

  .main-nav-footer-logo {
    left: 50%;
    text-align: center;
    margin: 0 auto;
    padding: rem-calc(16);
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
