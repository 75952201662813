.clean-screen {
  flex: 1;
  width: 100%;
  .clebex-pill-link {
    display: block;
    width: 100%;
    text-decoration: none !important;
  }
  .screen-footer {
    display: flex;
    justify-content: center;
    .clean-footer-menu {
      width: rem-calc(373);
      height: 100%;
      .menu-items {
        justify-content: space-evenly;
      }
    }
  }
  .actions-top-wrapper {
    background-color: $white;
  }
  .info-modal {
    .modal-header {
      svg {
        fill: $success-color;
      }
    }
  }
}
